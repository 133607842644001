import React from 'react';
import withRoot from './utils/withRoot';
import { StateProvider } from './context/store';
import MainContent from './pages/mainContent';
import {
    ADD_NEW_VEHICLE_SHOWING,
    CALC_TABLE_EXPAND,
    CALCULATIONS,
    SCENARIO_INPUTS,
    VEHICLE_SELECTION_DETAILS,
    VEHICLE_YEARS,
    VEHICLES_LIST
} from "./context/constants";
import AEPFooter from "./components/aepFooter";
import ReactGA from 'react-ga';
import './App.css';

function initializeReactGA() {
    ReactGA.initialize(
        [
            {
                trackingId: window._env_.REACT_APP_GA_ID,
                gaOptions: {
                    name: 'defaultTracker'
                }
            },
            {
                trackingId: window._env_.REACT_APP_GA_ID_CCOM,
                gaOptions: { name: 'CCOMTracker' }
            }
        ],
        { debug: true, alwaysSendToDefaultTracker: true });
    ReactGA.pageview('/homepage', ['defaultTracker', 'CCOMTracker']);
}

function App(props) {
    const { classes } = props;

    const initialState = {
        scenarioInputs: {
            annualMileage: 15000,
            weeksDrivenPerYear: 50,
            daysDrivenPerWeek: 5,
            gasolinePrice: 0.00,
            electricityPrice: 0.00,
            purchaseLeasePeriod: 8,
            purchaseLeaseRate: 3.0,
            financeType: 'Lease',
            ownershipPeriod: 10,
            useResidualValue: true,
            dailyMileage: 0,
            discountRate: 2.0,
            state: '',
            salesTax: 0
        },
        vehicleYears: [],
        vehicleSelectionDetails: {
            year: '',
            make: '',
            model: '',
            option: '',
            vehicleMakes: [],
            vehicleModels: [],
            vehicleOptions: [],
            vehicleStats: '',
            msrp: null,
            taxCredit: 0,
            isComparisonVehicle: false
        },
        vehicles: [],
        calculations: [],
        addNewVehicleShowing: false,
        lineColors: [
            '#2a9bd6',
            '#937ab8',
            '#d54728',
            '#e18338',
            '#1da5b4',
            '#f5b133',
            '#e64780',
            '#76bd43',
            '#8c8b90'
        ],
        showingColumns: {
            vehiclesRow: { isShowing: true, label: 'Vehicle Name' },
            msrpRow: { isShowing: true, label: 'MSRP' },
            taxCredRow: { isShowing: true, label: 'Tax Credit' },
            netPresCostRow: { isShowing: true, label: 'Net Present Cost' },
            pevRow: { isShowing: true, label: 'PEV Suitability' },
            lifeTotalEmissRow: { isShowing: true, label: 'Lifetime Total Emissions' },
            gasMPGRow: { isShowing: false, label: 'Gasoline MPG' },
            elecEffRow: { isShowing: false, label: 'Electrical Efficiency' },
            tailEmissRow: { isShowing: false, label: 'Tailpipe Emissions' },
            upGasEmissRow: { isShowing: false, label: 'Upstream Gasoline Emissions' },
            upElecEmissRow: { isShowing: false, label: 'Upstream Electric Emissions' },
            allElecRangeRow: { isShowing: false, label: 'All-Electric Range' },
            annVehPayRow: { isShowing: false, label: 'Annual Vehicle Payment' },
            residualValueRow: { isShowing: false, label: 'Residual Value' },
            dayElecConsumpRow: { isShowing: false, label: 'Daily Electric Consumption' },
            dayGasConsumpRow: { isShowing: false, label: 'Daily Gasoline Consumption' },
            firstYrElecCostRow: { isShowing: false, label: 'First Year Electric Cost' },
            firstYrGasCostRow: { isShowing: false, label: 'First Year Gasoline Cost' },
            firstYrGasEmissRow: { isShowing: false, label: 'First Year Gasoline Emissions' },
            firstYrElecEmissRow: { isShowing: false, label: 'First Year Electric Emissions' }
        },
        calcTableExpand: false
    };

    const reducer = (state, action) => {
        switch (action.type) {
            case SCENARIO_INPUTS:
                return {
                    ...state,
                    scenarioInputs: action.scenarioInputs
                };
            case VEHICLE_YEARS:
                return {
                    ...state,
                    vehicleYears: action.vehicleYears
                };
            case VEHICLE_SELECTION_DETAILS:
                return {
                    ...state,
                    vehicleSelectionDetails: action.vehicleSelectionDetails
                };
            case ADD_NEW_VEHICLE_SHOWING:
                return {
                    ...state,
                    addNewVehicleShowing: action.addNewVehicleShowing
                };
            case VEHICLES_LIST:
                return {
                    ...state,
                    vehicles: action.vehicles
                };
            case CALCULATIONS:
                return {
                    ...state,
                    calculations: action.calculations
                };
            case CALC_TABLE_EXPAND:
                return {
                    ...state,
                    calcTableExpand: action.calcTableExpand
                };
            default:
                return state;
        }
    };

    return (
        <div onLoad={initializeReactGA} className="App">
            <StateProvider initialState={initialState} reducer={reducer}>
                <MainContent />
                <AEPFooter classes={classes} class="footer" />
            </StateProvider>
            <script
              dangerouslySetInnerHTML={{
                __html: `
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','GTM-WPK3P78');
            `, }} />
        </div>
    );
}

export default withRoot(App);
