import React, {useEffect, useState } from 'react';
import PropTypes from "prop-types";
import {useStateValue} from "../context/store";
import Dialog from "@material-ui/core/Dialog";
import MaterialUIForm from 'react-material-ui-form';
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/es/Divider/Divider";
import DialogContent from "@material-ui/core/DialogContent";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import axios from "axios";
import {ADD_NEW_VEHICLE_SHOWING, EXCLUDED_MAKES, VEHICLE_SELECTION_DETAILS, VEHICLE_YEARS} from "../context/constants";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {EV_LIST} from "../context/ev-list";
import {EV_CREDITS} from "../context/ev-credits";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    dialogTitle: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        fontSize: 20,
        padding: 16,
        textTransform: 'uppercase'
    },
    textField: {
        marginTop: theme.spacing(1),
        width: '100%',
    },
    margin: {
        padding: theme.spacing(1),
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    menuItem: {
        paddingLeft: 16,
        paddingRight: 16,
        fontSize: 14,
    },
    dialog: {
        maxWidth: 'md'
    },
    button: {
        color: '#0078b7'
    }
}));

export default function AddNewVehicleDialog(props) {
    const classes = useStyles();
    const { addNewVehicle, vehicleSelectionDetails } = props;
    const [{ addNewVehicleShowing, vehicleYears, vehicles }, dispatch] = useStateValue();
    const [submitReady, setSubmitReady] = useState(true);

    function updateVehicleDetails(name, value) {
        switch (name) {
            case 'year':
                vehicleSelectionDetails.year = value;
                if (vehicleSelectionDetails.make !== '') vehicleSelectionDetails.make = '';
                if (vehicleSelectionDetails.model !== '') vehicleSelectionDetails.model = '';
                if (vehicleSelectionDetails.option !== '') vehicleSelectionDetails.option = '';
                if (vehicleSelectionDetails.vehicleMakes !== []) vehicleSelectionDetails.vehicleMakes = [];
                if (vehicleSelectionDetails.vehicleModels !== []) vehicleSelectionDetails.vehicleModels = [];
                if (vehicleSelectionDetails.vehicleOptions !== []) vehicleSelectionDetails.vehicleOptions = [];
                if (vehicleSelectionDetails.vehicleStats !== '') vehicleSelectionDetails.vehicleStats = '';
                break;
            case 'make':
                vehicleSelectionDetails.make = value;
                if (vehicleSelectionDetails.model !== '') vehicleSelectionDetails.model = '';
                if (vehicleSelectionDetails.option !== '') vehicleSelectionDetails.option = '';
                if (vehicleSelectionDetails.vehicleModels !== []) vehicleSelectionDetails.vehicleModels = [];
                if (vehicleSelectionDetails.vehicleOptions !== []) vehicleSelectionDetails.vehicleOptions = [];
                if (vehicleSelectionDetails.vehicleStats !== '') vehicleSelectionDetails.vehicleStats = '';
                break;
            case 'model':
                vehicleSelectionDetails.model = value;
                if (vehicleSelectionDetails.option !== '') vehicleSelectionDetails.option = '';
                if (vehicleSelectionDetails.vehicleOptions !== []) vehicleSelectionDetails.vehicleOptions = [];
                if (vehicleSelectionDetails.vehicleStats !== '') vehicleSelectionDetails.vehicleStats = '';
                break;
            case 'option':
                vehicleSelectionDetails.option = value;
                if (vehicleSelectionDetails.vehicleStats !== '') vehicleSelectionDetails.vehicleStats = '';
                break;
            case 'makes':
                vehicleSelectionDetails.vehicleMakes = value;
                break;
            case 'models':
                vehicleSelectionDetails.vehicleModels = value;
                break;
            case 'options':
                vehicleSelectionDetails.vehicleOptions = value;
                break;
            case 'stats':
                vehicleSelectionDetails.vehicleStats = value;
                break;
            case 'msrp':
                vehicleSelectionDetails.msrp = value;
                break;
            case 'taxCredit':
                vehicleSelectionDetails.taxCredit = value;
                break;
            default:
                break;
        }
        dispatch({
            type: VEHICLE_SELECTION_DETAILS,
            vehicleSelectionDetails: vehicleSelectionDetails
        })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    function hasComparisonVehicle() {
        let temp = false;
        vehicles.forEach(vehicle => {
            if (vehicle.isComparisonVehicle) temp = true;
        });
        return temp;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    function getVehicleMakes(year) {
        if (hasComparisonVehicle()) {
            // filters Excluded Makes out, sorts, then maps to same object type received from API
            updateVehicleDetails(
                'makes', Object.keys(EV_LIST[year])
                    .filter(make => !EXCLUDED_MAKES.includes(make))
                    .sort()
                    .map(make => { return { text: make, value: make } }));
        } else {
            axios.get(`https://www.fueleconomy.gov/ws/rest/vehicle/menu/make?year=${year}`, { timeout: 8000 })
                .then(function (response) {
                    let makes;
                    if (Array.isArray(response.data.menuItem)) {
                        makes = response.data.menuItem;
                    } else {
                        makes = [response.data.menuItem];
                    }
                    makes = makes.filter(make => !EXCLUDED_MAKES.includes(make.text));
                    updateVehicleDetails('makes', makes);
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }

    function getVehicleModels(make) {
        if (hasComparisonVehicle()) {
            updateVehicleDetails('models', EV_LIST[vehicleSelectionDetails.year][make].map(model => { return { text: model, value: model } }));
        } else {
            axios.get(`https://www.fueleconomy.gov/ws/rest/vehicle/menu/model?year=${vehicleSelectionDetails.year}&make=${make}`, { timeout: 8000 })
                .then(function (response) {
                    let models = response.data;
                    if (Array.isArray(models.menuItem)) {
                        updateVehicleDetails('models', models.menuItem);
                    } else {
                        updateVehicleDetails('models', [models.menuItem]);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }

    function getVehicleOptions(model) {
        axios.get(`https://www.fueleconomy.gov/ws/rest/vehicle/menu/options?year=${vehicleSelectionDetails.year}&make=${vehicleSelectionDetails.make}&model=${model}`, { timeout: 8000 })
            .then(function (response) {
                const options = response.data;
                if (Array.isArray(options.menuItem)) {
                    updateVehicleDetails('options', options.menuItem);
                } else {
                    updateVehicleDetails('options', [options.menuItem]);
                    updateVehicleDetails('option', [options.menuItem.value]);
                    vehicleOptionSelected(options.menuItem.value);
                }
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    function getVehicleDetails(vehicleId) {
        setSubmitReady(false);
        axios.get(`https://www.fueleconomy.gov/ws/rest/vehicle/${vehicleId}`, { timeout: 8000 })
            .then(function (response) {
                const details = response.data;
                updateVehicleDetails('stats', details);
                if (vehicleSelectionDetails.vehicleStats.fuelType === "Electricity" || vehicleSelectionDetails.vehicleStats.fuelType2 === "Electricity") {
                    getVehicleTaxCredit(vehicleSelectionDetails.make, vehicleSelectionDetails.vehicleStats.fuelType === "Electricity" ? "EV" : "PHEV");
                }
                setSubmitReady(true);

            })
            .catch(function (error) {
                console.log(error);
            });
    }
    function getVehicleMSRP(vehicleId) {
        
        axios.get(window._env_.REACT_APP_EV_SERVICE_URL + `?id=${vehicleId}`, { timeout: 8000 })
            .then(function (response) {
                let msrp = response.data.msrpLow;
                updateVehicleDetails('msrp', msrp);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    function getVehicleTaxCredit(make, atvType) {
        try {
            let credit = EV_CREDITS[make][atvType];
            if (credit !== undefined && credit > 0) {
                updateVehicleDetails('taxCredit', credit);
            }
        } catch{ }
      
    }
    function vehicleOptionSelected(option) {
        updateVehicleDetails('option', option);
        getVehicleDetails(option);
        getVehicleMSRP(option);
    }
    useEffect(() => {
        function getVehicleYears() {
            axios.get('https://www.fueleconomy.gov/ws/rest/vehicle/menu/year', { timeout: 8000 })
                .then(function (response) {

                    // Only show the last 10 years for the first vehicle, 3 years for additional vehicles
                    var years = response.data.menuItem.slice(0, 10);
                    // don't show next years cars
                    if(years[0].text === (new Date().getFullYear() + 1).toString()) {
                        years.shift();
                    }
                    dispatch({
                        type: VEHICLE_YEARS,
                        vehicleYears: years
                    })
                })
                .catch(function (error) {
                    console.log(error);
                });
        }

        if (vehicleYears.length === 0 || (!hasComparisonVehicle() && vehicleYears.length < 1)) {
            getVehicleYears()
        } else if (hasComparisonVehicle() && vehicleYears.length > 3) {
            const years = Object.keys(EV_LIST).sort().reverse().slice(0, 3);
            const yearSelectList = years.map(year => { return { "text": year, "value": year } });

            dispatch({
                type: VEHICLE_YEARS,
                vehicleYears: yearSelectList
            })
        }

        if (isNotEmpty(vehicleSelectionDetails.year) && vehicleSelectionDetails.vehicleMakes.length === 0) {
            getVehicleMakes(vehicleSelectionDetails.year)
        }

    }, [dispatch, getVehicleMakes, hasComparisonVehicle, vehicleSelectionDetails.vehicleMakes.length, vehicleSelectionDetails.year, vehicleYears, vehicles.length]);

    const handleSelectYear = event => {
        if (isNotEmpty(vehicleSelectionDetails.make)) vehicleSelectionDetails.make = '';
        if (isNotEmpty(vehicleSelectionDetails.model)) vehicleSelectionDetails.model = '';
        if (isNotEmpty(vehicleSelectionDetails.option)) vehicleSelectionDetails.option = '';
        if (!vehicleSelectionDetails.vehicleStats) vehicleSelectionDetails.stats = '';
        updateVehicleDetails('year', event.target.value);
        getVehicleMakes(event.target.value);
    };

    const handleSelectMake = event => {
        if (isNotEmpty(vehicleSelectionDetails.model)) vehicleSelectionDetails.model = '';
        if (isNotEmpty(vehicleSelectionDetails.option)) vehicleSelectionDetails.option = '';
        updateVehicleDetails('make', event.target.value);
        getVehicleModels(event.target.value);
    };

    const handleSelectModel = event => {
        if (isNotEmpty(vehicleSelectionDetails.option)) vehicleSelectionDetails.option = '';
        updateVehicleDetails('model', event.target.value);
        getVehicleOptions(event.target.value);
    };

    const handleSelectOption = event => {
        vehicleOptionSelected(event.target.value);
    };

    const handleClose = () => {
        dispatch({
            type: ADD_NEW_VEHICLE_SHOWING,
            addNewVehicleShowing: false
        })
    };

    function isNotEmpty(str) {
        return (str || str.length >= 1);
    }

    return (
        <div>
            <Dialog open={addNewVehicleShowing} onClose={handleClose} fullWidth className={classes.dialog}>
                <MaterialUIForm autoComplete="off">
                    <Typography id="form-dialog-title" className={classes.dialogTitle}>
                        {hasComparisonVehicle() ? "Add electric car to compare" : "Add Current Car"}
                    </Typography>
                    <Divider />
                    <DialogContent>
                        <FormControl
                            className={classes.textField}
                            fullWidth
                        >
                            <FormHelperText id="year-helper-text">Year</FormHelperText>
                            <Select
                                id="year"
                                autoFocus
                                value={vehicleSelectionDetails.year}
                                onChange={handleSelectYear}
                                aria-describedby="year-helper-text"
                                displayEmpty>
                                <MenuItem key={"year.text"} value="" disabled
                                    className={classes.menuItem}><em>Select a year</em></MenuItem>
                                {vehicleYears.map(year => (
                                    <MenuItem
                                        key={year.text}
                                        value={year.value}
                                        className={classes.menuItem}>
                                        {year.value}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl
                            className={classes.textField}
                            fullWidth
                        >
                            <FormHelperText id="make-helper-text">Make</FormHelperText>
                            <Select
                                id="make"
                                value={vehicleSelectionDetails.make}
                                onChange={(event) => handleSelectMake(event)}
                                aria-describedby="make-helper-text" displayEmpty>
                                <MenuItem key={"year.text"} value="" disabled
                                    className={classes.menuItem}><em>Select a make</em></MenuItem>
                                {vehicleSelectionDetails.vehicleMakes.map(make => (
                                    <MenuItem
                                        key={make.text}
                                        value={make.value}
                                        className={classes.menuItem}>
                                        {make.value}
                                    </MenuItem>
                                ))}
                            </Select>

                         
                        </FormControl>
                        <FormControl
                            className={classes.textField}
                            fullWidth
                        >
                            <FormHelperText id="model-helper-text">Model</FormHelperText>
                            <Select
                                id="model"
                                value={vehicleSelectionDetails.model}
                                onChange={(event) => handleSelectModel(event)}
                                aria-describedby="model-helper-text" displayEmpty>
                                <MenuItem key={"year.text"} value="" disabled
                                    className={classes.menuItem}><em>Select a model</em></MenuItem>
                                {vehicleSelectionDetails.vehicleModels.map(model => (
                                    <MenuItem
                                        key={model.text}
                                        value={model.value}
                                        className={classes.menuItem}>
                                        {model.value}
                                    </MenuItem>
                                ))}
                            </Select>

                           
                        </FormControl>
                        <FormControl
                            className={classes.textField}
                            fullWidth>
                            <FormHelperText id="option-helper-text">Trim</FormHelperText>
                            <Select
                                id="option"
                                value={vehicleSelectionDetails.option}
                                onChange={(event) => handleSelectOption(event)}
                                aria-describedby="option-helper-text" displayEmpty>
                                <MenuItem key={"year.text"} value="" disabled
                                    className={classes.menuItem}><em>Select trim</em></MenuItem>
                                {vehicleSelectionDetails.vehicleOptions.map(option => (
                                    <MenuItem
                                        key={option.text}
                                        value={option.value}
                                        className={classes.menuItem}>
                                        {option.text}
                                    </MenuItem>
                                ))}
                            </Select>

                            
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} variant="outlined" className={classes.button}>
                            Cancel
                        </Button>
                        <Button onClick={addNewVehicle} variant="outlined" className={classes.button} disabled={!submitReady}>
                            Add Car
                        </Button>
                    </DialogActions>
                </MaterialUIForm>
            </Dialog>
        </div>
    )
};

AddNewVehicleDialog.propTypes = {
    updateVehicleDetails: PropTypes.func,
    addNewVehicle: PropTypes.func,
    vehicleSelectionDetails: PropTypes.object
};
