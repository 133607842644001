export const EV_LIST ={
  "2019": {
    "BMW": [
      "I8 Coupe",
      "I8 Roadster",
      "530e",
      "530e xDrive",
      "740e xDrive",
      "i3",
      "i3s",
      "i3 with Range Extender",
      "i3s with Range Extender"
    ],
    "MINI": [
      "Cooper SE Countryman All4"
    ],
    "Hyundai": [
      "Ioniq Electric",
      "Kona Electric",
      "Ioniq Plug-in Hybrid",
      "Sonata Plug-in Hybrid"
    ],
    "Volvo": [
      "S90 AWD PHEV",
      "XC60 AWD PHEV",
      "XC90 AWD PHEV",
      "S60 AWD PHEV"
    ],
    "Chevrolet": [
      "Bolt EV",
      "Volt"
    ],
    "Kia": [
      "Soul Electric",
      "Optima Plug-in Hybrid",
      "Niro Plug-in Hybrid",
      "Niro Electric"
    ],
    "Volkswagen": [
      "e-Golf"
    ],
    "Ford": [
      "Fusion Energi Plug-in Hybrid",
      "Fusion Special Service Vehicle PHEV"
    ],
    "Nissan": [
      "Leaf (40 kW-hr battery pack)",
      "Leaf (62 kW-hr battery pack)",
      "Leaf SV/SL (62 kW-hr battery pack)"
    ],
    "Chrysler": [
      "Pacifica Hybrid"
    ],
    "Jaguar": [
      "I-Pace"
    ],
    "Mercedes-Benz": [
      "GLC350e 4matic",
      "S560e"
    ],
    "Mitsubishi": [
      "Outlander PHEV"
    ],
    "Subaru": [
      "Crosstrek Hybrid AWD"
    ],
    "Fiat": [
      "500e"
    ],
    "Honda": [
      "Clarity Plug-in Hybrid",
      "Clarity EV"
    ],
    "Toyota": [
      "Prius Prime"
    ],
    "smart": [
      "EQ fortwo (coupe)",
      "EQ fortwo (convertible)"
    ],
    "Tesla": [
      "Model 3 Mid Range",
      "Model 3 Long Range",
      "Model 3 Long Range AWD",
      "Model 3 Long Range AWD Performance",
      "Model S 75D",
      "Model S 100D",
      "Model S P100D",
      "Model X 75D",
      "Model X 100D",
      "Model X P100D",
      "Model 3 Standard Range",
      "Model 3 Standard Range Plus",
      "Model S Long Range",
      "Model S Performance (19in Wheels)",
      "Model S Performance (21in Wheels)",
      "Model S Standard Range",
      "Model X Long Range",
      "Model X Performance (22in Wheels)"
    ],
    "Porsche": [
      "Panamera 4 e-Hybrid",
      "Panamera 4 e-Hybrid Executive",
      "Panamera 4 e-Hybrid ST",
      "Panamera Turbo S e-Hybrid",
      "Panamera Turbo S e-Hybrid Executive",
      "Panamera Turbo S e-Hybrid ST",
      "Cayenne e-Hybrid"
    ],
    "Audi": [
      "e-tron"
    ],
    "Karma": [
      "Revero"
    ],
    "Land Rover": [
      "Range Rover PHEV",
      "Range Rover Sport PHEV"
    ]
  },
  "2020": {
    "BMW": [
      "745e xDrive",
      "530e",
      "530e xDrive",
      "I8 Coupe",
      "I8 Roadster",
      "i3",
      "i3s",
      "X3 xDrive30e",
      "i3 with Range Extender",
      "i3s with Range Extender"
    ],
    "Jaguar": [
      "I-Pace"
    ],
    "Toyota": [
      "Prius Prime"
    ],
    "Kia": [
      "Optima Plug-in Hybrid",
      "Niro Plug-in Hybrid",
      "Niro Electric",
      "Soul Electric"
    ],
    "Ford": [
      "Fusion Energi Plug-in Hybrid",
      "Fusion Special Service PHEV",
      "Escape FWD PHEV"
    ],
    "Chrysler": [
      "Pacifica Hybrid"
    ],
    "Volvo": [
      "S60 AWD PHEV",
      "S90 AWD PHEV",
      "V60 AWD PHEV",
      "XC60 AWD PHEV",
      "XC90 AWD PHEV"
    ],
    "BYD": [
      "e6"
    ],
    "Hyundai": [
      "Kona Electric",
      "Ioniq Electric",
      "Ioniq Plug-in Hybrid"
    ],
    "Mitsubishi": [
      "Outlander PHEV"
    ],
    "Porsche": [
      "Panamera Turbo S e-Hybrid",
      "Panamera Turbo S e-Hybrid Executive",
      "Panamera Turbo S e-Hybrid ST",
      "Panamera 4 e-Hybrid",
      "Panamera 4 e-Hybrid Executive",
      "Panamera 4 e-Hybrid ST",
      "Taycan Turbo",
      "Taycan Turbo S",
      "Cayenne e-Hybrid",
      "Cayenne e-Hybrid Coupe",
      "Taycan 4S Perf Battery Plus",
      "Cayenne Turbo S e-Hybrid",
      "Cayenne Turbo S e-Hybrid Coupe"
    ],
    "Chevrolet": [
      "Bolt EV"
    ],
    "Tesla": [
      "Model 3 Long Range",
      "Model 3 Long Range AWD",
      "Model 3 Mid Range",
      "Model 3 Standard Range",
      "Model 3 Standard Range Plus",
      "Model 3 Long Range Performance AWD (18in)",
      "Model 3 Long Range Performance AWD (19in)",
      "Model 3 Long Range Performance AWD (20in)",
      "Model S Long Range",
      "Model S Performance (19in Wheels)",
      "Model S Performance (21in Wheels)",
      "Model S Standard Range",
      "Model X Long Range",
      "Model X Performance (20in Wheels)",
      "Model X Performance (22in Wheels)",
      "Model X Standard Range",
      "Model Y Performance AWD",
      "Model S Long Range Plus",
      "Model Y Performance AWD (21in Wheels)",
      "Model Y Long Range AWD",
      "Model X Long Range Plus"
    ],
    "Karma": [
      "Revero GT (21-inch wheels)"
    ],
    "Subaru": [
      "Crosstrek Hybrid AWD"
    ],
    "MINI": [
      "Cooper SE Countryman All4",
      "Cooper SE Hardtop 2 door"
    ],
    "Land Rover": [
      "Range Rover PHEV",
      "Range Rover Sport PHEV"
    ],
    "Lincoln": [
      "Aviator PHEV AWD"
    ],
    "Audi": [
      "A8 L",
      "Q5",
      "e-tron Sportback"
    ],
    "Honda": [
      "Clarity Plug-in Hybrid"
    ],
    "Mercedes-Benz": [
      "S560e",
      "GLC350e 4matic"
    ],
    "Bentley": [
      "Bentayga"
    ],
    "Nissan": [
      "Leaf (40 kW-hr battery pack)",
      "Leaf (62 kW-hr battery pack)",
      "Leaf SV/SL (62 kW-hr battery pack)"
    ],
    "Polestar": [
      "1"
    ]
  },
  "2021": {
    "BMW": [
      "330e",
      "330e xDrive",
      "745e xDrive",
      "X5 xDrive45e",
      "i3s",
      "530e",
      "530e xDrive",
      "X3 xDrive30e",
      "i3 with Range Extender",
      "i3s with Range Extender"
    ],
    "MINI": [
      "Cooper SE Hardtop 2 door",
      "Cooper SE Countryman All4"
    ],
    "Toyota": [
      "RAV4 Prime 4WD",
      "Prius Prime"
    ],
    "Volvo": [
      "XC90 AWD PHEV",
      "S90 AWD PHEV",
      "V60 AWD PHEV",
      "XC60 AWD PHEV",
      "XC40 AWD BEV",
      "S60 AWD PHEV"
    ],
    "Honda": [
      "Clarity Plug-in Hybrid"
    ],
    "Audi": [
      "A8 L",
      "A7 quattro",
      "Q5",
      "e-tron",
      "e-tron Sportback"
    ],
    "Polestar": [
      "1",
      "2"
    ],
    "Lincoln": [
      "Aviator PHEV AWD"
    ],
    "Ferrari": [
      "SF90 Stradale Coupe"
    ],
    "Land Rover": [
      "Range Rover PHEV",
      "Range Rover Sport PHEV"
    ],
    "Tesla": [
      "Model 3 Long Range AWD",
      "Model 3 Performance AWD",
      "Model X Long Range Plus",
      "Model X Performance (20in Wheels)",
      "Model X Performance (22in Wheels)",
      "Model Y Long Range AWD",
      "Model Y Performance AWD",
      "Model S Performance (19in Wheels)",
      "Model S Performance (21in Wheels)"
    ],
    "Karma": [
      "Revero GT (21-inch wheels)",
      "Revero GT (22-inch wheels)",
      "GS-6 (21-inch wheels)",
      "GS-6 (22-inch wheels)"
    ],
    "Chrysler": [
      "Pacifica Hybrid"
    ],
    "Volkswagen": [
      "ID.4 1st",
      "ID.4 Pro S"
    ],
    "Ford": [
      "Mustang Mach-E AWD",
      "Mustang Mach-E AWD Extended",
      "Mustang Mach-E RWD",
      "Mustang Mach-E RWD Extended",
      "Mustang Mach-E RWD California Route 1"
    ],
    "Kandi": [
      "K27"
    ],
    "Chevrolet": [
      "Bolt EV"
    ],
    "Nissan": [
      "Leaf (40 kW-hr battery pack)",
      "Leaf (62 kW-hr battery pack)",
      "Leaf SV/SL (62 kW-hr battery pack)"
    ],
    "Porsche": [
      "Taycan 4S Perf Battery",
      "Taycan 4S Perf Battery Plus"
    ],
    "Kia": [
      "Niro Plug-in Hybrid"
    ],
    "Subaru": [
      "Crosstrek Hybrid AWD"
    ],
    "Bentley": [
      "Bentayga"
    ],
    "Hyundai": [
      "Ioniq Plug-in Hybrid",
      "Kona Electric"
    ],
    "Mitsubishi": [
      "Outlander PHEV"
    ]
  }
}