import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    footer: {
        flexShrink: 0,
        left: 0,
        bottom: 0,
        width: '100%',
        backgroundColor: theme.palette.primary.dark,
        color: 'white',
        paddingBottom: '30px',
        paddingTop: '40px',
    },
    disclaimer: {
        paddingRight: '20px',
        paddingLeft: '20px'
    },
    a: {
        color: '#fff',
        fontFamily: ["din-2014","Arial","sans-serif","Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"].join(","),
        textDecoration: 'none'
    }
}));

export default function AEPFooter() {
    const classes = useStyles();

    return (
        <footer className={classes.footer}>
            <div className={classes.disclaimer}>
                <p>
                    Use of this site constitutes acceptance of the <a href="/terms/" className={classes.a}>AEP Terms and Conditions</a>.
                    <span style={{whiteSpace: 'nowrap'}}>© 1996-2019</span><br/>
                    American Electric Power Company, Inc. All Rights Reserved.
                </p>
            </div>
        </footer>
    )
}
