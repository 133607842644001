import React, { useEffect, useState } from "react";
import './calculationsTable.css';
import { useStateValue } from "../context/store";
import ReactDataSheet from 'react-datasheet';
import 'react-datasheet/lib/react-datasheet.css';
import { InputBase, TextField, withStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography/Typography";
import TableRow from "@material-ui/core/TableRow/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import AddNewVehicleDialog from "../components/addNewVehicleDialog";
import {
    ADD_NEW_VEHICLE_SHOWING,
    CALC_TABLE_EXPAND,
    CALCULATIONS,
    STATE_DATA,
    VEHICLE_SELECTION_DETAILS,
    VEHICLES_LIST
} from "../context/constants";
import AddIcon from '@material-ui/icons/Add';
import MinusIcon from '@material-ui/icons/Remove'
import Button from "@material-ui/core/Button/Button";
import DeleteIcon from '@material-ui/icons/Delete';
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import NumberFormat from 'react-number-format';
import ReactGA from 'react-ga';
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import InfoButton from "./infoButton";


const ExpansionPanelSummary = withStyles({
    root: {
        backgroundColor: 'rgba(0,0,0,.05)',
        borderBottom: '1px solid rgba(0,0,0,.125)',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },

    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(props => <MuiExpansionPanelSummary {...props} />);

ExpansionPanelSummary.muiName = 'ExpansionPanelSummary';

const ExpansionPanel = withStyles({
    root: {

    },
    disabled: {
        backgroundColor: '#fff !important'
    },
})(props => <MuiExpansionPanel {...props} />);

const CalculationsTable = () => {
    const [{ calculations, vehicles, scenarioInputs, vehicleSelectionDetails, showingColumns, calcTableExpand }, dispatch] = useStateValue();
    // here begins the creation of the calculation table rows and headers
    const [vehiclesRow] = useState([{
        value: 'Vehicle',
        hasDelete: false,
        isBold: true,
        type: 'text'
    }]);
    const [msrpRow] = useState([{
        value: 'MSRP',
        hasDelete: false,
        isBold: true,
        type: 'text'
    }]);
    const [taxCredRow] = useState([{
        value: 'Tax Credit Eligibility',
        hasDelete: false,
        isBold: true,
        infoText: 'For vehicle-specific tax credit information visit ',
        infoLink: 'https://www.fueleconomy.gov/feg/taxevb.shtml',
        type: 'text'
    }]);
    const [netPresCostRow] = useState([{
        value: 'NET PRESENT COST',
        hasDelete: false,
        isBold: true,
        type: 'text'
    }]);
    const [pevRow] = useState([{
        value: 'DAILY BATTERY USE %',
        hasDelete: false,
        isBold: true,
        type: 'text'
    }]);
    const [lifeTotalEmissRow] = useState([{
        value: 'LIFETIME TOTAL CO2e EMISSIONS (kg)',
        hasDelete: false,
        isBold: true,
        type: 'text'
    }]);
    const [gasMPGRow] = useState([{
        value: 'Gasoline MPG',
        hasDelete: false,
        infoText: 'Values included for BEVs are MPGe. 1 MPG = 1 mi/(33.70 kWh)',
        type: 'text'
    }]);
    const [elecEffRow] = useState([{
        value: 'Electrical efficiency (kWh / 100 mi)',
        hasDelete: false,
        type: 'text'
    }]);
    const [tailEmissRow] = useState([{
        value: 'Tailpipe Emissions (kg/mi)',
        hasDelete: false,
        type: 'text'
    }]);
    const [upGasEmissRow] = useState([{
        value: 'Upstream Gasoline Emissions (kg/mi)',
        hasDelete: false,
        type: 'text'
    }]);
    const [upElecEmissRow] = useState([{
        value: 'Upstream Electric Emissions (kg/kWh)',
        hasDelete: false,
        type: 'text'
    }]);
    const [allElecRangeRow] = useState([{
        value: 'All-Electric Range (mi)',
        hasDelete: false,
        type: 'text'
    }]);
    const [annVehPayRow] = useState([{
        value: 'Annual Vehicle Payment',
        hasDelete: false,
        type: 'text'
    }]);
    const [residualValueRow] = useState([{
        value: 'Residual Value',
        hasDelete: false,
        type: 'text'
    }]);
    const [dayElecConsumpRow] = useState([{
        value: 'Daily Electric Consumption (kWh)',
        hasDelete: false,
        type: 'text'
    }]);
    const [dayGasConsumpRow] = useState([{
        value: 'Daily Gasoline Consumption (gal)',
        hasDelete: false,
        type: 'text'
    }]);
    const [firstYrElecCostRow] = useState([{
        value: 'First Year Electricity Cost',
        hasDelete: false,
        type: 'text'
    }]);
    const [firstYrGasCostRow] = useState([{
        value: 'First Year Gasoline Cost',
        hasDelete: false,
        type: 'text'
    }]);
    const [firstYrGasEmissRow] = useState([{
        value: 'First Year Gasoline Emissions (kg)',
        hasDelete: false,
        type: 'text'
    }]);
    const [firstYrElecEmiss] = useState([{
        value: 'First Year Electricity Emissions (kg)',
        hasDelete: false,
        type: 'text'
    }]);
    const [showMore, setShowMore] = useState(false);

    const depreciationCurve = [.8, .6, .5, .45, .405, .365, .328, .295, .28, .27];

    // Removes all data from rows except headers, then adds each vehicle's data in order
    function initializeColumns() {
        vehiclesRow.splice(1, vehiclesRow.length - 1);
        msrpRow.splice(1, msrpRow.length - 1);
        taxCredRow.splice(1, taxCredRow.length - 1);
        netPresCostRow.splice(1, netPresCostRow.length - 1);
        pevRow.splice(1, pevRow.length - 1);
        lifeTotalEmissRow.splice(1, lifeTotalEmissRow.length - 1);
        gasMPGRow.splice(1, gasMPGRow.length - 1);
        elecEffRow.splice(1, elecEffRow.length - 1);
        tailEmissRow.splice(1, tailEmissRow.length - 1);
        upGasEmissRow.splice(1, upGasEmissRow.length - 1);
        upElecEmissRow.splice(1, upElecEmissRow.length - 1);
        allElecRangeRow.splice(1, allElecRangeRow.length - 1);
        annVehPayRow.splice(1, annVehPayRow.length - 1);
        residualValueRow.splice(1, residualValueRow.length - 1);
        dayElecConsumpRow.splice(1, dayElecConsumpRow.length - 1);
        dayGasConsumpRow.splice(1, dayGasConsumpRow.length - 1);
        firstYrElecCostRow.splice(1, firstYrElecCostRow.length - 1);
        firstYrGasCostRow.splice(1, firstYrGasCostRow.length - 1);
        firstYrGasEmissRow.splice(1, firstYrGasEmissRow.length - 1);
        firstYrElecEmiss.splice(1, firstYrElecEmiss.length - 1);

        calculations.forEach(calc => {
            processVehicleData(calc, -1);
        })
    }

    // initialize the hide/show section of the table
    function buildColumns() {
        let filteredColumns = [];

        if (showingColumns.vehiclesRow.isShowing) filteredColumns.push(vehiclesRow);
        if (showingColumns.msrpRow.isShowing) filteredColumns.push(msrpRow);
        if (showingColumns.taxCredRow.isShowing) filteredColumns.push(taxCredRow);
        if (showingColumns.netPresCostRow.isShowing) filteredColumns.push(netPresCostRow);
        if (showingColumns.pevRow.isShowing) filteredColumns.push(pevRow);
        if (showingColumns.lifeTotalEmissRow.isShowing) filteredColumns.push(lifeTotalEmissRow);
        if (showingColumns.gasMPGRow.isShowing) filteredColumns.push(gasMPGRow);
        if (showingColumns.elecEffRow.isShowing) filteredColumns.push(elecEffRow);
        if (showingColumns.tailEmissRow.isShowing) filteredColumns.push(tailEmissRow);
        if (showingColumns.upGasEmissRow.isShowing) filteredColumns.push(upGasEmissRow);
        if (showingColumns.upElecEmissRow.isShowing) filteredColumns.push(upElecEmissRow);
        if (showingColumns.allElecRangeRow.isShowing) filteredColumns.push(allElecRangeRow);
        if (showingColumns.annVehPayRow.isShowing) filteredColumns.push(annVehPayRow);
        if (showingColumns.residualValueRow.isShowing) filteredColumns.push(residualValueRow);
        if (showingColumns.dayElecConsumpRow.isShowing) filteredColumns.push(dayElecConsumpRow);
        if (showingColumns.dayGasConsumpRow.isShowing) filteredColumns.push(dayGasConsumpRow);
        if (showingColumns.firstYrElecCostRow.isShowing) filteredColumns.push(firstYrElecCostRow);
        if (showingColumns.firstYrGasCostRow.isShowing) filteredColumns.push(firstYrGasCostRow);
        if (showingColumns.firstYrGasEmissRow.isShowing) filteredColumns.push(firstYrGasEmissRow);
        if (showingColumns.firstYrElecEmissRow.isShowing) filteredColumns.push(firstYrElecEmiss);

        return filteredColumns;
    }

    useEffect(() => {
        if (vehicles && vehicles.length) {
            for (let i = 0; i < calculations.length; i++) {
                removeVehicleData(i + 1);
                doCalculations(calculations[i], i, vehicles[i].vehicleStats)
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scenarioInputs.ownershipPeriod, scenarioInputs.annualMileage, scenarioInputs.purchaseLeaseRate,
    scenarioInputs.state, scenarioInputs.salesTax, scenarioInputs.gasolinePrice, scenarioInputs.electricityPrice,
    scenarioInputs.weeksDrivenPerYear, scenarioInputs.daysDrivenPerWeek, scenarioInputs.dailyMileage,
    scenarioInputs.purchaseLeasePeriod, scenarioInputs.financeType, scenarioInputs.ownershipPeriod, scenarioInputs.useResidualValue, 
    scenarioInputs.discountRate, calculations.msrp, calculations.taxCredit, scenarioInputs.taxCredit]);

    const handleAddNewButtonClick = (event) => {
        if (vehicles.length < 5) {
            dispatch({
                type: ADD_NEW_VEHICLE_SHOWING,
                addNewVehicleShowing: true
            });
        }
        //Google Analytics Event
        ReactGA.event({
            category: 'User',
            action: 'Add Vehicle Button Clicked.'
        }, ['defaultTracker', 'CCOMTracker']);
    };

    const handleShowMoreClick = () => {
        //Google Analytics Event
        ReactGA.event({
            category: 'User',
            action: 'Show More Button Clicked'
        }, ['defaultTracker', 'CCOMTracker']);

        setShowMore(!showMore);
        showingColumns.gasMPGRow.isShowing = !showMore;
        showingColumns.elecEffRow.isShowing = !showMore;
        showingColumns.tailEmissRow.isShowing = !showMore;
        showingColumns.upGasEmissRow.isShowing = !showMore;
        showingColumns.upElecEmissRow.isShowing = !showMore;
        showingColumns.annVehPayRow.isShowing = !showMore;
        showingColumns.residualValueRow.isShowing = !showMore;
        showingColumns.dayElecConsumpRow.isShowing = !showMore;
        showingColumns.dayGasConsumpRow.isShowing = !showMore;
        showingColumns.firstYrElecCostRow.isShowing = !showMore;
        showingColumns.firstYrGasCostRow.isShowing = !showMore;
        showingColumns.firstYrGasEmissRow.isShowing = !showMore;
        showingColumns.firstYrElecEmissRow.isShowing = !showMore;
    };

    function hasComparisonVehicle() {
        let temp = false;
        vehicles.forEach(vehicle => {
            if (vehicle.isComparisonVehicle) temp = true;
        });
        return temp;
    }

    // Adds each vehicle's calculations to the respective row
    function processVehicleData(calc, index) {

        vehiclesRow[index] ? vehiclesRow[index] = {
            value: calc.vehicle,
            hasDelete: true,
            type: 'text'
        } : vehiclesRow.push({ value: calc.vehicle, hasDelete: true, type: 'text' });

        msrpRow[index] ? msrpRow[index] = {
            value: calc.msrp,
            hasDelete: false,
            type: 'currency',
            isInput: true
        } : msrpRow.push({ value: calc.msrp, hasDelete: false, type: 'currency', isInput: true });

        taxCredRow[index] ? taxCredRow[index] = ({
            value: calc.taxCredit,
            hasDelete: false,
            type: 'currency',
            isInput: true
        }) : taxCredRow.push({ value: calc.taxCredit, hasDelete: false, type: 'currency', isInput: true });

        netPresCostRow[index] ? netPresCostRow[index] = ({
            value: calc.netPresentCost,
            hasDelete: false,
            type: 'currency',
            contentsBold: true
        }) : netPresCostRow.push({ value: calc.netPresentCost, hasDelete: false, type: 'currency', contentsBold: true });

        pevRow[index] ? pevRow[index] = ({
            value: calc.pevSuitablity,
            hasDelete: false,
            type: 'percent',
            contentsBold: true
        }) : pevRow.push({ value: calc.pevSuitablity, hasDelete: false, type: 'percent', contentsBold: true });

        lifeTotalEmissRow[index] ? lifeTotalEmissRow[index] = ({
            value: calc.lifeTimeTotalEmiss,
            hasDelete: false,
            type: 'number',
            contentsBold: true
        }) : lifeTotalEmissRow.push({ value: calc.lifeTimeTotalEmiss, hasDelete: false, type: 'number', contentsBold: true });

        gasMPGRow[index] ? gasMPGRow[index] = {
            value: calc.gasMPG,
            hasDelete: false,
            type: 'number'
        } : gasMPGRow.push({ value: calc.gasMPG, hasDelete: false, type: 'number' });

        elecEffRow[index] ? elecEffRow[index] = ({
            value: calc.elecEff,
            hasDelete: false,
            type: 'number'
        }) : elecEffRow.push({ value: calc.elecEff, hasDelete: false, type: 'number' });

        tailEmissRow[index] ? tailEmissRow[index] = ({
            value: calc.tailEmiss,
            hasDelete: false,
            type: 'number'
        }) : tailEmissRow.push({ value: calc.tailEmiss, hasDelete: false, type: 'number' });

        upGasEmissRow[index] ? upGasEmissRow[index] = ({
            value: calc.upGasEmiss,
            hasDelete: false,
            type: 'number'
        }) : upGasEmissRow.push({ value: calc.upGasEmiss, hasDelete: false, type: 'number' });

        upElecEmissRow[index] ? upElecEmissRow[index] = ({
            value: calc.upElecEmiss,
            hasDelete: false,
            type: 'number'
        }) : upElecEmissRow.push({ value: calc.upElecEmiss, hasDelete: false, type: 'number' });

        allElecRangeRow[index] ? allElecRangeRow[index] = ({
            value: calc.allElecRange,
            hasDelete: false,
            type: 'number'
        }) : allElecRangeRow.push({ value: calc.allElecRange, hasDelete: false, type: 'number' });

        annVehPayRow[index] ? annVehPayRow[index] = ({
            value: calc.annVehPay,
            hasDelete: false,
            type: 'currency'
        }) : annVehPayRow.push({ value: calc.annVehPay, hasDelete: false, type: 'currency' });
        
        residualValueRow[index] ? residualValueRow[index] = ({
            value: calc.residualValue,
            hasDelete: false,
            type: 'currency'
        }) : residualValueRow.push({ value: calc.residualValue, hasDelete: false, type: 'currency' });

        dayElecConsumpRow[index] ? dayElecConsumpRow[index] = ({
            value: calc.dayElecConsump,
            hasDelete: false,
            type: 'number'
        }) : dayElecConsumpRow.push({ value: calc.dayElecConsump, hasDelete: false, type: 'number' });

        dayGasConsumpRow[index] ? dayGasConsumpRow[index] = ({
            value: calc.dayGasconsump,
            hasDelete: false,
            type: 'number'
        }) : dayGasConsumpRow.push({ value: calc.dayGasconsump, hasDelete: false, type: 'number' });

        firstYrElecCostRow[index] ? firstYrElecCostRow[index] = ({
            value: calc.firstYrElecCost,
            hasDelete: false,
            type: 'currency'
        }) : firstYrElecCostRow.push({ value: calc.firstYrElecCost, hasDelete: false, type: 'currency' });

        firstYrGasCostRow[index] ? firstYrGasCostRow[index] = ({
            value: calc.firstYrGasCost,
            hasDelete: false,
            type: 'currency'
        }) : firstYrGasCostRow.push({ value: calc.firstYrGasCost, hasDelete: false, type: 'currency' });

        firstYrGasEmissRow[index] ? firstYrGasEmissRow[index] = ({
            value: calc.firstYrGasEmiss,
            hasDelete: false,
            type: 'number'
        }) : firstYrGasEmissRow.push({ value: calc.firstYrGasEmiss, hasDelete: false, type: 'number' });

        firstYrElecEmiss[index] ? firstYrElecEmiss[index] = ({
            value: calc.firstYrElecEmissRow,
            hasDelete: false,
            type: 'number'
        }) : firstYrElecEmiss.push({ value: calc.firstYrElecEmissRow, hasDelete: false, type: 'number' });
    }


    function removeVehicleData(index) {
        vehiclesRow.splice(index, 1);
        msrpRow.splice(index, 1);
        taxCredRow.splice(index, 1);
        netPresCostRow.splice(index, 1);
        pevRow.splice(index, 1);
        lifeTotalEmissRow.splice(index, 1);
        gasMPGRow.splice(index, 1);
        elecEffRow.splice(index, 1);
        tailEmissRow.splice(index, 1);
        upGasEmissRow.splice(index, 1);
        upElecEmissRow.splice(index, 1);
        allElecRangeRow.splice(index, 1);
        annVehPayRow.splice(index, 1);
        residualValueRow.splice(index, 1);
        dayElecConsumpRow.splice(index, 1);
        dayGasConsumpRow.splice(index, 1);
        firstYrElecCostRow.splice(index, 1);
        firstYrGasCostRow.splice(index, 1);
        firstYrGasEmissRow.splice(index, 1);
        firstYrElecEmiss.splice(index, 1);
    }

    const addNewVehicleToList = () => {
        if (vehicleSelectionDetails.option) {
            let index = vehicles.findIndex(x => x.vehicleStats.id === vehicleSelectionDetails.id);
            if (index === -1) {
                if (!hasComparisonVehicle()) {
                    vehicleSelectionDetails.isComparisonVehicle = true;
                    vehicles.unshift(vehicleSelectionDetails);
                } else {
                    vehicles.push(vehicleSelectionDetails);
                }

                dispatch({
                    type: VEHICLES_LIST,
                    vehicles: vehicles
                });
                dispatch({
                    type: ADD_NEW_VEHICLE_SHOWING,
                    addNewVehicleShowing: false
                });
                dispatch({
                    type: VEHICLE_SELECTION_DETAILS,
                    vehicleSelectionDetails: {
                        year: '',
                        make: '',
                        model: '',
                        option: '',
                        vehicleMakes: [],
                        vehicleModels: [],
                        vehicleOptions: [],
                        vehicleStats: '',
                        msrp: 0,
                        taxCredit: 0,
                        isComparisonVehicle: false
                    }
                });
            }

            doCalculations(null, -1, vehicleSelectionDetails.vehicleStats);
        }
        ReactGA.event({
            category: 'User',
            action: 'User Added a vehicle'
        }, ['defaultTracker', 'CCOMTracker']);
    }

    // creates a vehicles calculations object
    function doCalculations(calculation, index, vehicleStats) {
        let vehicleType = 0; // Standard Gasoline Vehicle
        if (vehicleStats.fuelType === "Electricity") vehicleType = 1; // All Electric Vehicle
        if (vehicleStats.fuelType2 === "Electricity") vehicleType = 2; // Plug-In Hybrid Vehicle

        let tempCalc = null;
        if (calculation) {
            tempCalc = getVehicleCalculations(vehicleStats, vehicleType, calculation);
        } else {
            tempCalc = getVehicleCalculations(vehicleStats, vehicleType);
            tempCalc.msrp = vehicleSelectionDetails.msrp;
            tempCalc.taxCredit = vehicleSelectionDetails.taxCredit;
        }
        if (scenarioInputs.ownershipPeriod !== undefined) {
            tempCalc.residualValues = getResidudalValues(tempCalc.msrp, scenarioInputs.ownershipPeriod);
            tempCalc.residualValue = tempCalc.residualValues[scenarioInputs.ownershipPeriod -1];//(tempCalc.msrp && scenarioInputs.useResidualValue) ? tempCalc.msrp * .86667 * Math.pow(0.9, parseInt(scenarioInputs.ownershipPeriod)) : 0;
        } else {
            tempCalc.residualValues = [];
            tempCalc.residualValue = 0;
        } 

        tempCalc.annVehPay = tempCalc.msrp === 0 ? 0 : parseFloat(PMT((scenarioInputs.purchaseLeaseRate / 100), scenarioInputs.purchaseLeasePeriod, (scenarioInputs.financeType !== "Lease" ? ((-(1 + parseFloat(scenarioInputs.salesTax)) * tempCalc.msrp)) : (-(parseFloat(tempCalc.msrp) - parseFloat(tempCalc.taxCredit)))), scenarioInputs.financeType === "Lease" ? tempCalc.residualValue : 0).toFixed(2));
        if (scenarioInputs.financeType === "Lease") {
            tempCalc.annVehPay = (1 + parseFloat(scenarioInputs.salesTax)) * tempCalc.annVehPay;
        }
        tempCalc.financials.gas = tempCalc.firstYrGasCost === 'N/A' ? [] : getVehicleGasFinancials(tempCalc.firstYrGasCost);
        tempCalc.financials.elec = tempCalc.firstYrElecCost === 'N/A' ? [] : getVehicleElectricityFinancials(tempCalc.firstYrElecCost);
        tempCalc.financials.maint =  getVehicleMaintFinancials(vehicleType);
        tempCalc.financials.pmt = getVehiclePMTFinancials(tempCalc.annVehPay, tempCalc.taxCredit);
        tempCalc.financials.totals = getVehicleTotalAmounts(tempCalc);
        tempCalc.financials.cumulative = getVehicleCumulativeAmounts(tempCalc);
        tempCalc.emissions = getVehicleEmissions(tempCalc.firstYrGasEmiss, tempCalc.firstYrElecEmissRow);
        if (scenarioInputs.ownershipPeriod !== undefined) {
            tempCalc.netPresentCost = parseFloat(getNPV((parseFloat(scenarioInputs.discountRate) / 100), tempCalc.financials.totals, parseInt(scenarioInputs.ownershipPeriod)).toFixed(2));
        } else {
            tempCalc.netPresentCost = 0;
        }
        if(scenarioInputs.useResidualValue && scenarioInputs.financeType !== "Lease")tempCalc.netPresentCost -= tempCalc.residualValue;


        tempCalc.lifeTimeTotalEmiss = getLifeTimeVehicleEmissions(tempCalc.emissions);

        if (!calculation) {
            if (vehicleSelectionDetails.isComparisonVehicle) {
                calculations.unshift(tempCalc);
            } else {
                calculations.push(tempCalc);
            }
        } else {
            calculations[index] = tempCalc;
        }
        dispatch({
            type: CALCULATIONS,
            calculations: calculations
        });
        initializeColumns()
    }

    function getVehicleCalculations(vehicleDetails, vehicleType, previousValues = 0) {

        let vehicle = vehicleDetails.year + ' ' + vehicleDetails.make + ' ' + vehicleDetails.model;
        let msrp = previousValues.msrp || 0;
        let gasMPG = vehicleDetails.comb08U;
        let elecEff = vehicleDetails.combE;
        let tailEmiss = vehicleType === 1 ? "N/A" : parseFloat((vehicleDetails.co2TailpipeGpm / (1 - vehicleDetails.combinedUF)/1000).toFixed(2));
        let upGasEmiss = vehicleType === 1 ? "N/A" : parseFloat(((vehicleDetails.co2TailpipeGpm / (1 - vehicleDetails.combinedUF)/1000) * 0.30).toFixed(2));
        let upElecEmiss = vehicleType === 0 ? "N/A" : parseFloat((STATE_DATA[scenarioInputs.state].electricEmissionRate/1000).toFixed(2));
        let allElecRange = vehicleType === 0 ? "N/A" : parseFloat(vehicleType === 1 ? vehicleDetails.range : vehicleDetails.rangeCityA);
        let pevSuitablity = vehicleType === 0 ? "N/A" : parseFloat(((scenarioInputs.dailyMileage / allElecRange) * 100).toFixed(0));
        if (pevSuitablity > 100) pevSuitablity = 100;
        let taxCredit = previousValues.taxCredit || 0;
        let annVehPay = previousValues.annVehPay || 0;
        let residualValue = previousValues.residualValue || 0;
        let residualValues = previousValues.residualValues || [];
        let dayElecConsump = vehicleType === 0 ? "N/A" : (allElecRange > scenarioInputs.dailyMileage ? (elecEff * scenarioInputs.dailyMileage / 100) : (elecEff * allElecRange / 100)).toFixed(2);
        let dayGasconsump = vehicleType === 1 ? "N/A" : (scenarioInputs.dailyMileage / vehicleDetails.comb08U).toFixed(2);
        if (vehicleType === 2) {
            dayGasconsump = allElecRange < scenarioInputs.dailyMileage ? ((scenarioInputs.dailyMileage - allElecRange) / vehicleDetails.comb08U).toFixed(2) : 0;
        }
        let firstYrElecCost = vehicleType === 0 ? "N/A" : ((scenarioInputs.daysDrivenPerWeek * scenarioInputs.weeksDrivenPerYear)) * (((dayElecConsump / 0.9) * scenarioInputs.electricityPrice)).toFixed(2);
        let firstYrGasCost = vehicleType === 1 ? "N/A" : ((scenarioInputs.weeksDrivenPerYear * scenarioInputs.daysDrivenPerWeek) * (dayGasconsump * scenarioInputs.gasolinePrice)).toFixed(2);
        let firstYrGasEmiss = vehicleType === 1 ? "N/A" : vehicleType === 0 ? ((tailEmiss + upGasEmiss) * scenarioInputs.annualMileage).toFixed(0) : (scenarioInputs.dailyMileage > allElecRange ? ((tailEmiss + upGasEmiss) * (scenarioInputs.dailyMileage - allElecRange) * scenarioInputs.daysDrivenPerWeek * scenarioInputs.weeksDrivenPerYear).toFixed(0) : 0);
        let firstYrElecEmiss = vehicleType === 0 ? "N/A" : (upElecEmiss * dayElecConsump * scenarioInputs.daysDrivenPerWeek * scenarioInputs.weeksDrivenPerYear).toFixed(0);
        let netPresentCost = previousValues.netPresentCost || 0;
        let lifeTimeTotalEmiss = previousValues.lifeTimeTotalEmiss || 0;

        return {
            vehicle: vehicle,
            msrp: msrp,
            taxCredit: taxCredit,
            netPresentCost: netPresentCost,
            pevSuitablity: pevSuitablity,
            lifeTimeTotalEmiss: lifeTimeTotalEmiss,
            gasMPG: gasMPG,
            elecEff: elecEff,
            tailEmiss: tailEmiss,
            upGasEmiss: upGasEmiss,
            upElecEmiss: upElecEmiss,
            allElecRange: allElecRange,
            annVehPay: annVehPay,
            residualValue: residualValue,
            residualValues: residualValues,
            dayElecConsump: dayElecConsump,
            dayGasconsump: dayGasconsump,
            firstYrElecCost: firstYrElecCost,
            firstYrGasCost: firstYrGasCost,
            firstYrGasEmiss: firstYrGasEmiss,
            firstYrElecEmissRow: firstYrElecEmiss,
            financials: previousValues.financials || {
                gas: [],
                elec: [],
                maint: [],
                pmt: [],
                totals: [],
                cumulative: []
            },
            emissions: previousValues.emissions || []
        }
    }

    function getVehicleGasFinancials(firstYearGasCost) {
        let money = [];
        let ownershipLogic = 1;
        let gasMoney = 0;

        for (let i = 1; i <= scenarioInputs.ownershipPeriod; i++) {
            if (i > scenarioInputs.ownershipPeriod) ownershipLogic = 0;

            if (i === 1) {
                gasMoney = parseFloat(firstYearGasCost).toFixed(0);
            } else {
                gasMoney = (gasMoney * 1.05 * ownershipLogic).toFixed(0);
            }
            money.push(gasMoney);
        }

        return money;
    }

    function getVehicleElectricityFinancials(firstYearElecCost) {
        let money = [];
        let ownershipLogic = 1;
        let elecMoney = 0;
        if (scenarioInputs.ownershipPeriod !== undefined) {
            if (scenarioInputs.ownershipPeriod !== undefined) {
                for (let i = 1; i <= scenarioInputs.ownershipPeriod; i++) {
                    if (i > scenarioInputs.ownershipPeriod) ownershipLogic = 0;

                    if (i === 1) {
                        elecMoney = parseFloat(firstYearElecCost).toFixed(0);
                    } else {
                        elecMoney = (elecMoney * 1.023 * ownershipLogic).toFixed(0);
                    }
                    money.push(elecMoney);
                }
            }
        }

        return money;
    }

    function getVehicleMaintFinancials(vehicleType) {
        let money = [];
        let ownershipLogic = 1;
        let maintMoney = 0;
        if (scenarioInputs.ownershipPeriod !== undefined) {
            for (let i = 1; i <= scenarioInputs.ownershipPeriod; i++) {
                if (i > scenarioInputs.ownershipPeriod) ownershipLogic = 0;

                if (vehicleType === 0) {
                    maintMoney = ownershipLogic * (Math.round(scenarioInputs.annualMileage * 0.06)).toFixed(0);//(500 + 100 + (100 * Math.round((scenarioInputs.annualMileage - 5000) / 5000)))).toFixed(0);
                } else {
                    maintMoney = ownershipLogic * (Math.round(scenarioInputs.annualMileage * 0.03)).toFixed(0); //(100 + (100 * Math.round((scenarioInputs.annualMileage - 5000) / 5000)))).toFixed(0);
                }
                money.push(maintMoney);
            }
        }

        return money;
    }

    function getVehiclePMTFinancials(annualVehiclePayment, taxCredit) {
        let money = [];
        let purchaseLogic = 1;
        let pmtMoney = 0;
        if (scenarioInputs.ownershipPeriod !== undefined) {
            for (let i = 1; i <= scenarioInputs.ownershipPeriod; i++) {
                if (i > scenarioInputs.purchaseLeasePeriod) purchaseLogic = 0;
                if (scenarioInputs.financeType !== "Lease" && i === 1) {
                    pmtMoney = (annualVehiclePayment * purchaseLogic) - parseFloat(taxCredit);
                } else {
                    pmtMoney = (annualVehiclePayment * purchaseLogic).toFixed(0);
                }
                
                money.push(pmtMoney);
            }
        }
        return money;
    }

    function getVehicleTotalAmounts(tempCalc) {
        let money = [];
        if (scenarioInputs.ownershipPeriod !== undefined) {
            for (let i = 0; i < scenarioInputs.ownershipPeriod; i++) {
                let gas = (tempCalc.financials.gas && tempCalc.financials.gas.length > 0) ? parseFloat(tempCalc.financials.gas[i]) : 0;
                let elec = (tempCalc.financials.elec && tempCalc.financials.elec.length > 0) ? parseFloat(tempCalc.financials.elec[i]) : 0;
                let maint = (tempCalc.financials.maint && tempCalc.financials.maint.length > 0) ? parseFloat(tempCalc.financials.maint[i]) : 0;
                let pmt = parseFloat(tempCalc.financials.pmt[i]);
                let total = (gas + elec + maint + pmt).toFixed(0);
                money.push(total);
            }
        }

        return money;
    }

    function getVehicleCumulativeAmounts(tempCalc) {
        let money = [];
        let amount = 0;

        tempCalc.financials.totals.forEach(function (element) {
            amount += parseInt(element);
            money.push(amount);
        });
        return money;
    }

    function getVehicleEmissions(gasFirstYrEmissions, electricFirstYrEmissions) {
        let emissions = [];
        let gasEmission = 0;
        let electricEmission = 0;
        let ownershipLogic = 1;
        if (scenarioInputs.ownershipPeriod !== undefined) {
            for (let i = 1; i <= scenarioInputs.ownershipPeriod; i++) {
                if (i > scenarioInputs.ownershipPeriod) ownershipLogic = 0;

                if (i === 1) {
                    gasEmission = gasFirstYrEmissions === "N/A" ? 0 : gasFirstYrEmissions;
                    electricEmission = electricFirstYrEmissions === "N/A" ? 0 : electricFirstYrEmissions;
                } else {
                    gasEmission = (gasEmission * ownershipLogic).toFixed(0);
                    electricEmission = (electricEmission * 0.975 * ownershipLogic).toFixed(0);
                }
                emissions.push(parseInt(gasEmission) + parseInt(electricEmission));
            }
        }

        return emissions;
    }

    function getLifeTimeVehicleEmissions(vehicleEmissions) {
        let total = 0;
        if (scenarioInputs.ownershipPeriod !== undefined) {
            for (let i = 0; i < scenarioInputs.ownershipPeriod; i++) {
                total += parseInt(vehicleEmissions[i]);
            }
        }
        return total;
    }

    /**
     * Copy of Excel's PMT function.
     * Credit: http://stackoverflow.com/questions/2094967/excel-pmt-function-in-js
     *
     * @param rate_per_period       The interest rate for the loan.
     * @param number_of_payments    The total number of payments for the loan in months.
     * @param present_value         The present value, or the total amount that a series of future payments is worth now;
     *                              Also known as the principal.
     * @param future_value          The future value, or a cash balance you want to attain after the last payment is made.
     *                              If fv is omitted, it is assumed to be 0 (zero), that is, the future value of a loan is 0.
     * @param type                  Optional, defaults to 0. The number 0 (zero) or 1 and indicates when payments are due.
     *                              0 = At the end of period
     *                              1 = At the beginning of the period
     * @returns {number}
     * PMT((scenarioInputs.purchaseLeaseRate / 100), scenarioInputs.purchaseLeasePeriod, (-(1 + scenarioInputs.salesTax) * tempCalc.msrp) + parseFloat(tempCalc.taxCredit)).toFixed(2);
     */
    function PMT(rate_per_period, number_of_payments, present_value, future_value = 0, type = 0) {

        if (!isNaN(present_value)) {
            if (rate_per_period !== 0.0) {
                // Interest rate exists
                let q = Math.pow(1 + rate_per_period, number_of_payments);
                return -(rate_per_period * (future_value + (q * present_value))) / ((-1 + q) * (1 + rate_per_period * (type)));

            } else if (number_of_payments !== 0.0) {
                // No interest rate, but number of payments exists
                return -(future_value + present_value) / number_of_payments;
            }
        }

        return 0;
    }

    /**
     * Calculates the Net Present Value of an array of cash flow values with the specified discount rate.
     *
     * @param {number} rate - The discount rate percentage
     * @param {array} values - An array of future payment amounts
     * @param {number} ownershipPeriod - The total number of years
     * @return {number} The calculated Net Present Value
     */
    function getNPV(rate, values, ownershipPeriod) {
        let npv = 0;

        for (let i = ownershipPeriod; i > 0; i -= 1) {
            npv = ((parseFloat(values[i - 1]) + npv) / (1 + rate));
        }
        return npv;
    }

    function getResidudalValues(msrp, numYears) {
        let values = [];
        for (let i = 0; i < numYears && i < 10; i++) {
            values[i] = msrp * depreciationCurve[i];
        }
        return values;
    }

    function deleteVehicle(event, index) {
        calculations.splice(index - 1, 1);
        vehicles.splice(index - 1, 1);
        removeVehicleData(index);
        ReactGA.event({
            category: 'User',
            action: 'User deleted a vehicle'
        }, ['defaultTracker', 'CCOMTracker']);
        dispatch({
            type: CALCULATIONS,
            calculations: calculations
        });
        dispatch({
            type: VEHICLES_LIST,
            vehicles: vehicles
        })
    }

    const handleExpand = (event) => {
        event.preventDefault();
        dispatch({
            type: CALC_TABLE_EXPAND,
            calcTableExpand: !calcTableExpand
        })
    };

    function handleOnCellDataChange(props, values) {
        const rowMapping = { 1: 'msrp', 2: 'taxCredit' };
        let { value } = values;
        calculations[props.col - 1][rowMapping[props.row]] = value;
    }

    function getCellForData(props) {
        switch (props.cell.type) {
            case 'currency':
                return (
                    <TableCell className='td' aria-readonly={true}>
                        <NumberFormat
                            value={props.cell.value}
                            thousandSeparator={true}
                            placeholder={"Enter MSRP"}
                            prefix={'$'}
                            decimalScale={0}
                            onFocus={(event) => {
                                event.target.select();
                            }}
                            displayType={props.cell.isInput ? 'input' : 'text'}
                            customInput={TextField}
                            className={props.cell.contentsBold ? 'celldatabold' : 'celldata'}
                            onValueChange={values => handleOnCellDataChange(props, values)}
                            onBlur={event => doCalculations(calculations[props.col - 1], props.col - 1, vehicles[props.col - 1].vehicleStats)} />
                    </TableCell>
                );
            case 'number':
                return (
                    <TableCell className='td' aria-readonly={true}>
                        <NumberFormat value={props.cell.value} thousandSeparator={true} displayType={'text'}
                            customInput={InputBase} decimalScale={2} className={props.cell.contentsBold ? 'celldatabold' : 'celldata'} />
                    </TableCell>
                );
            case 'percent':
                let color = '';
                if (props.cell.value < 75) color = '#76bd43';
                else if (props.cell.value < 85) color = '#f5b133';
                else if (props.cell.value >= 85) color = '#d54728';
                else color = '#ffffff';
                return (
                    <TableCell style={{ backgroundColor: color }} aria-readonly={true}>
                        <NumberFormat value={props.cell.value} thousandSeparator={true} suffix={'%'} decimalScale={0} 
                            onFocus={(event) => { event.target.select();}}
                            displayType={'text'} customInput={InputBase} className={props.cell.contentsBold ? 'celldatabold' : 'celldata'} />
                    </TableCell>
                );
            default:
                return (
                    <TableCell className='td' aria-readonly={true}>
                        <div className='row'>
                            <Typography style={{ fontWeight: props.cell.isBold ? '600' : '500' }}>{props.cell.value}</Typography>
                            <div className='growRow' />
                            {props.cell.hasDelete ?
                                <Button onClick={(event) => deleteVehicle(event, props.col)}>
                                    <DeleteIcon />
                                </Button>
                                : null}
                            {props.cell.infoText ?
                                <InfoButton infoText={props.cell.infoText} infoLink={props.cell.infoLink} />
                                : null
                            }
                        </div>
                    </TableCell>
                );
        }
    }

    /**
     * https://github.com/nadbm/react-datasheet
     * Instructions for the ReactDataSheet.
     */

    return (
        <ExpansionPanel expanded={calcTableExpand} onChange={(event) => handleExpand(event)} disabled={scenarioInputs.state === ''}>
            <ExpansionPanelSummary className='toolbar' expandIcon={<ExpandMoreIcon />}>
                <Typography variant='h2' className='typography'>
                    Compare Cars
                    </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <div className='column'>
                    <ol className='ordered-list'>
                        <li>Choose <strong>your current car</strong> or another gas vehicle to use as a comparison.</li>
                        <li>Choose one or more electric cars to compare to.</li>
                    </ol>
                    <Button variant='contained' color="primary" disabled={vehicles.length > 4}
                        onClick={(event) => handleAddNewButtonClick(event)}
                        id='addNewVehicleBtn'>
                        <AddIcon className='extendedIcon' />
                        {hasComparisonVehicle() ? "Add Electric car" : "Add Current Car"}
                    </Button>
                    <div className={vehicles.length > 0 ? '' : 'hide'}>
                    <ReactDataSheet
                        className='table'
                        data={buildColumns()}
                        valueRenderer={(cell) => ((cell.value === 0 || cell.value === null) ? "" : cell.value)}
                        rowRenderer={props => (
                            <TableRow className='tr'>
                                {props.children}
                            </TableRow>
                        )}
                        cellRenderer={props => (
                            getCellForData(props)
                        )}
                    />
                    <AddNewVehicleDialog vehicleSelectionDetails={vehicleSelectionDetails}
                        addNewVehicle={addNewVehicleToList} />
                    <Button variant='contained' color="secondary" className='margin'
                        onClick={handleShowMoreClick}
                        id='showMoreDetailButton'>
                        {showMore ? <MinusIcon className='extendedIcon' /> : <AddIcon className='extendedIcon' />}
                        <Typography variant='button'
                            className='typography'>{showMore ? "Less Detail" : "More Detail"}</Typography>
                        </Button>
                    </div>
                </div>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    )
};


export default CalculationsTable;
