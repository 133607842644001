import React from 'react';
import ScenarioInputs from '../components/scenarioInputs';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import AEPLogo from '../assets/images/aep_white@2x.png';
import CalculationsTable from "../components/calculationsTable";
import VehicleFinancialsTable from "../components/vehicleFinancialsTable";
import VehicleEmissionsTable from "../components/vehicleEmissionsTable";
import {useStateValue} from "../context/store";
import makeStyles from "@material-ui/core/styles/makeStyles";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        flex: "1 0 auto",
        padding: theme.spacing(3),
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        height: '93px',
        backgroundColor: theme.palette.primary.main,
    },
    hide: {
        display: 'none',
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 20,
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 8px',
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
        color: theme.palette.common.white
    },
    toolbar: {
        display: 'flex',
        flexGrow: 1,
        height: '100%'
    },
    menuIconGrid: {
        flexWrap: 'wrap'
    },
    toolbarGrid: {
        flexGrow: 1,
        height: '100%'
    },
    menuIcon: {
        color: theme.palette.common.white
    },
    toolbarLogo: {
        height: '63px',
    },
    gridContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    appBarTitle: {
        marginLeft: theme.spacing(2)
    },
    tableRow: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
    },
    tableColumn: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },
    tableSpacer: {
        width: 16
    },
    main: {
        maxWidth: '70rem',
        minHeight: "78vh",
        margin: '0 auto'
    }
}));

export default function MainContent() {
    const classes = useStyles();
    const [{vehicles}] = useStateValue();

    return (
        <div className={classes.root}>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                    <Grid container>
                        <Grid item className={classes.gridContainer}>
                            <img src={AEPLogo} alt="" className={classes.toolbarLogo}/>
                            <Typography variant='h1' className={classes.appBarTitle}>Electric Car Calculator</Typography>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <main className={classes.main}>
                <div style={{height:"88px"}}/>
                <ScenarioInputs/>
                <CalculationsTable/>
                <br/>
                {vehicles.length > 0 ? <VehicleFinancialsTable/> : null}
                <br/>
                {vehicles.length > 0 ? <VehicleEmissionsTable/> : null}
            </main>
        </div>);
};
