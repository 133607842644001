import React, {createContext, useContext, useReducer} from 'react'

export const EVTotalCostContext = createContext();

export const StateProvider = ({reducer, initialState, children}) => (
    <EVTotalCostContext.Provider value={useReducer(reducer, initialState)}>
        {children}
    </EVTotalCostContext.Provider>
);

export const useStateValue = () => useContext(EVTotalCostContext);
