export const EV_CREDITS ={
"Audi": {
        "EV": 7500,
        "PHEV": 4502
    },
"BMW": {
        "EV": 7500,
        "PHEV": 4668
    },
"Cadillac": {
        "EV": 0,
        "PHEV": 0
    },
"Chevrolet": {
        "EV": 0,
        "PHEV": 0
    },
"Chrysler": {
        "EV": 0,
        "PHEV": 7500
    },
"Fiat": {
        "EV": 7500,
        "PHEV": 0
    },
"Fisker": {
        "EV": 7500,
        "PHEV": 7500 
    },
"Ford": {
        "EV": 7500,
        "PHEV": 4609
    },
"Honda": {
        "EV": 7500,
        "PHEV":3626 
    },
"Hyundai": {
        "EV": 7500,
        "PHEV": 4543
    },
"Jaguar": {
        "EV": 7500,
        "PHEV": 0
    },
"Karma": {
        "EV": 0,
        "PHEV": 7500
    },
"Kia": {
        "EV": 7500,
        "PHEV": 4543
    },
"Land Rover": {
        "EV": 7500,
        "PHEV": 7087
    },
"Lincoln": {
        "EV": 7500,
        "PHEV": 6534
    },
"Mercedes-Benz": {
        "EV": 7500,
        "PHEV": 3501
    },
"Mitsubishi": {
        "EV": 7500,
        "PHEV": 5836
    },
"Nissan": {
        "EV": 7500,
        "PHEV": 0
    },
"Porsche": {
        "EV": 7500,
        "PHEV": 5336
    },
"smart": {
        "EV": 7500,
        "PHEV": 0
    },
"Subaru": {
        "EV": 7500,
        "PHEV": 4502
    },
"Tesla": {
        "EV": 0,
        "PHEV": 0
    },
"Toyota": {
        "EV": 7500,
        "PHEV": 4502
    },
"VIA Motors": {
        "EV": 0,
        "PHEV": 7500 
    },
"Volkswagen": {
        "EV": 7500,
        "PHEV": 0
    },
"Volvo": {
        "EV": 0,
        "PHEV": 4585
    },
"Zenith Motors": {
        "EV": 7500,
        "PHEV": 0
    }

}