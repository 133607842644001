/* eslint-disable no-underscore-dangle */

import {SheetsRegistry} from 'jss';
// eslint-disable-next-line no-unused-vars
import {createMuiTheme} from '@material-ui/core/styles';

// A theme with custom primary and secondary color.
// It's optional.
const theme = createMuiTheme({
    palette: {
        type: 'light',
        primary: {
            light: '#e35347',
            main: '#dc291a',
            dark: '#9a1c12',
        },
        secondary: {
            light: '#63d1ff',
            main: '#0ca0da',
            dark: '#0072a8',
        },
        background: {
            paper: '#FFFFFF',
            default: '#4a4a4a'
        },
        action: {
            hover: "rgba(12, 160, 218, 0.5)"
        },
        text: {
            //secondary: '#2499DE'
        }
    },
    typography: {
        useNextVariants: true,
        fontFamily: [
            'din-2014',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"'
        ].join(','),
        headline: {
            fontFamily: "din-2014, Arial, sans-serif",
            fontWeight: 700,
            fontSize: "175px",
            color: "rgba(0,0,0,1.0)"
        },
        subheading: {
            fontFamily: "din-2014, Arial, sans-serif",
            fontWeight: 600,
            fontSize: "57px",
            color: "rgba(0,0,0,1.0)"
        },
        h1: {
            fontFamily: "din-2014, Arial, sans-serif",
            fontWeight: 700,
            color: "#ffffff",
            fontSize: "2.0rem",
            textTransform: "uppercase"
        },
        h2: {
            fontFamily: "din-2014, Arial, sans-serif",
            fontWeight: 700,
            color: "#ad222b",
            fontSize: "1.5rem",
            textTransform: "uppercase"
        },
        h3: {
            fontFamily: "din-2014, Arial, sans-serif",
            fontWeight: 700,
            color: "#ad222b",
            fontSize: "1.25rem",
            textTransform: "uppercase",
            marginBottom: "16px"
        },
        body1: {
            fontFamily: "din-2014, Arial, sans-serif",
            fontWeight: 200,
        },
        body2: {
            fontFamily: "din-2014, Arial, sans-serif",
            fontWeight: 200,
        },
        subtitle1: {
            fontFamily: "din-2014, Arial, sans-serif",
            fontWeight: 700,
            color: "#ad222b",
            fontSize: "1.0rem",
            textTransform: "uppercase"
        },
        subtitle2: {
            fontFamily: "din-2014, Arial, sans-serif",
            fontWeight: 200,
            fontSize: "13px",
            color: "#000000"
        },
        button: {
            fontSize: "1rem",
            fontFamily: "din-2014, Arial, sans-serif",
            fontWeight: 400,
            color: "#ffffff",
            textTransform: "uppercase"
        }
    },
});

function createPageContext() {
    return {
        theme,
        // This is needed in order to deduplicate the injection of CSS in the page.
        sheetsManager: new Map(),
        // This is needed in order to inject the critical CSS.
        sheetsRegistry: new SheetsRegistry(),
    };
}

export default function getPageContext() {
    // Make sure to create a new context for every server-side request so that data
    // isn't shared between connections (which would be bad).
    if (!process.browser) {
        return createPageContext();
    }

    // Reuse context on the client-side.
    if (!global.__INIT_MATERIAL_UI__) {
        global.__INIT_MATERIAL_UI__ = createPageContext();
    }

    return global.__INIT_MATERIAL_UI__;
}
