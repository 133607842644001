import React from 'react';
import { useStateValue } from "../context/store";
import { CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis, ResponsiveContainer } from 'recharts';

export default function CostOverLifeTimeChart() {
    const [{ calculations, scenarioInputs, lineColors }] = useStateValue();
    const data = [];

    function getData() {
        let element = {};
        if (scenarioInputs.ownershipPeriod !== undefined) {
            for (let i = 0; i < scenarioInputs.ownershipPeriod; i++) {
                element = { name: i + 1 };
                for (let j = 0; j < calculations.length; j++) {
                    let calc = calculations[j];
                    element[calc.vehicle] = calc.financials.cumulative[i];
                }
                data.push(element);
            }
        } else {
            data.push(element);
        }
    }

    function createLines() {
        let lines = [];
        if (calculations.length > 0) {
            getData();

            for (let i = 0; i < calculations.length; i++) {
                lines.push(<Line type="monotone" dataKey={calculations[i].vehicle} stroke={lineColors[i]} />)
            }
        }

        return lines;
    }

    return (
        <ResponsiveContainer width="100%" height={470}>
            <LineChart data={data} margin={{ top: 40, right: 20, left: 20, bottom: 20 }}>
            <XAxis dataKey="name" />
            <YAxis tickFormatter={value => '$' + new Intl.NumberFormat('en').format(value)} />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            <Legend />
            {createLines()}
            </LineChart>
        </ResponsiveContainer>
    )
};
