import React, { useState } from 'react';
import { useStateValue } from "../context/store";
import Paper from "@material-ui/core/es/Paper/Paper";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import MuiTableCell from "@material-ui/core/es/TableCell/TableCell";
import Table from "@material-ui/core/es/Table/Table";
import TableRow from "@material-ui/core/es/TableRow/TableRow";
import TableBody from "@material-ui/core/es/TableBody/TableBody";
import NumberFormat from 'react-number-format';
import TableHead from "@material-ui/core/TableHead";
import './vehicleFinancialsTable.css';
import EmissionsOverLifeTimeChart from "./emissionsOverLifeTimeChart";
import { Grid, withStyles } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button/Button";
import AddIcon from '@material-ui/icons/Add';
import MinusIcon from '@material-ui/icons/Remove'

const TableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    sizeSmall: {
        padding: '8px'
    }
}))(MuiTableCell);

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
    },
    table: {
        minWidth: 400,
    },
    row: {
        backgroundColor: '#f3f3f3',
    },
}));

export default function VehicleEmissionsTable() {
    const classes = useStyles();
    const [{ calculations, scenarioInputs }] = useStateValue();
    const [showEmissionsTable, setShowEmissionsTable] = useState(false);

    function createHeaders() {
        let headers = [<TableCell />];
        if (scenarioInputs.ownershipPeriod !== undefined) {
            for (let i = 1; i <= scenarioInputs.ownershipPeriod; i++) {
                headers.push(<TableCell size="small">
                    <Typography variant='subtitle2'>
                        {i.valueOf()}
                    </Typography>
                </TableCell>
                );
            }
        }
       
        return headers;
    }

    const toggleTable = () => {
        setShowEmissionsTable(!showEmissionsTable);
    }

    return (
        <Paper className='paperRoot'>
            <Toolbar className='toolbar'>
                <Typography variant='h2' className='typography' color="inherit">
                    Emissions Over Lifetime (kg of co2e)
                </Typography>
                <div className='growRow' />
            </Toolbar>
            
            <Grid container justify="center" alignContent="center">
                <Grid container item direction="row" lg={12} >
                    <EmissionsOverLifeTimeChart />
                </Grid>
                {showEmissionsTable ?
                    <Grid item direction="row" lg={12} style={{ overflowX: 'auto' }}>
                        <Table size="small" className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    {createHeaders()}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {calculations.map((calcs) => (
                                    <TableRow className="tableRow">
                                        <TableCell size="small" component="th" scope="row">
                                            {calcs.vehicle}
                                        </TableCell>
                                        {calcs.emissions.map(emission => (
                                            <TableCell size="small">
                                                <NumberFormat value={emission} thousandSeparator={true} displayType={'text'} />
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Grid>
                    : null}
            </Grid>
            <Button variant='contained' color="secondary" className='detailsButton' style={{ margin: '10px 0px 10px 24px' }}
                onClick={toggleTable}
                id='showEmissionsTableButton'>
                {showEmissionsTable ? <MinusIcon className='extendedIcon' /> : <AddIcon className='extendedIcon' />}
                <Typography variant='button'
                    className='typography'>{showEmissionsTable ? "Less Detail" : "More Detail"}</Typography>
            </Button>
        </Paper>
    )
};
