import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useStateValue } from '../context/store';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import Typography from "@material-ui/core/es/Typography/Typography";
import { API_KEY, CALC_TABLE_EXPAND, SCENARIO_INPUTS, STATE_DATA } from "../context/constants";
import MenuItem from "@material-ui/core/MenuItem";
import axios from 'axios';
import AssumptionsDialog from "../components/assumptionsDialog";
import NumberFormat from 'react-number-format';
import { Grid, Checkbox } from "@material-ui/core";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Button from "@material-ui/core/Button";
import NextIcon from '@material-ui/icons/NavigateNext';
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import clsx from "clsx";
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import IconButton from "@material-ui/core/IconButton";
import Grow from "@material-ui/core/Grow";
import makeStyles from "@material-ui/core/styles/makeStyles";
import InfoButton from "./infoButton";

const ExpansionPanelSummary = withStyles({
    root: {
        backgroundColor: 'rgba(0,0,0,.05)',
        borderBottom: '1px solid rgba(0,0,0,.125)',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
            backgroundColor: '#fff'
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    }, 
    expanded: {},
})(props => <MuiExpansionPanelSummary {...props} />);

ExpansionPanelSummary.muiName = 'ExpansionPanelSummary';


const useStyles = makeStyles(theme => ({
    root: {
        justifyContent: 'center',
        marginBottom: 16,
    },
    paper: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
    margin_Bottom: {
        marginBottom: 22
    },
    margin_Top: {
        marginTop: 22
    },
    label: {
        //padding: theme.spacing(3)
    },
    withoutLabel: {
        marginTop: theme.spacing(2)
    },
    subheader: {
        fontSize: theme.typography.pxToRem(32),
        textTransform: 'uppercase',

    },
    heading: {
        fontSize: theme.typography.pxToRem(18),
        fontWeight: theme.typography.fontWeightMedium,
        padding: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        textAlign: 'center'
    },
    menuItem: {
        paddingLeft: 16,
        fontSize: 14
    },
    instructions: {
        marginLeft: theme.spacing(3)
    },
    toolbar: {
        display: 'flex',
        backgroundColor: '#efeeed',
        "&$expanded": {
            backgroundColor: "#fff"
        }
    },
    topFix: {
        top: '50%'
    },
    backgroundLight: {
        backgroundColor: '#eeeeee'
    },
}));

export default function ScenarioInputs() {
    const classes = useStyles();
    const [{ scenarioInputs }, dispatch] = useStateValue();
    const [gasPrices, setGasPrices] = useState(null);
    const [elecPrice, setElecPrice] = useState(null);
    const [introExpanded, setIntroExpanded] = useState(true);
    const [profileExpanded, setProfileExpanded] = useState(false);
    const [snackBarOpen, setSnackBarOpen] = useState(false);

    function GrowTransition(props) {
        return <Grow {...props} />;
    }

    const useStyles1 = makeStyles(theme => ({
        error: {
            backgroundColor: '#ffffff',
            border: 'solid 1px ' + theme.palette.primary.main
        },
        icon: {
            fontSize: 20,
            color: theme.palette.primary.main
        },
        iconVariant: {
            opacity: 0.9,
            marginRight: 8,
            color: theme.palette.primary.main
        },
        message: {
            fontSize: 16,
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.primary.main
        },
    }));

    function MySnackbarContentWrapper(props) {
        const classes = useStyles1();
        const { message, onClose, className, ...other } = props;

        return (
            <SnackbarContent
                onClose={handleClose}
                className={clsx(classes.error, className)}
                aria-describedby="client-snackbar"
                message={
                    <span id="client-snackbar" className={classes.message}>
                        <ErrorIcon className={clsx(classes.icon, classes.iconVariant)} />
                        {message}
                    </span>
                }
                action={[
                    <IconButton key="close" aria-label="Close" color="inherit" onClick={onClose}>
                        <CloseIcon className={classes.icon} />
                    </IconButton>,
                ]}
                {...other}
            />
        );
    }

    MySnackbarContentWrapper.propTypes = {
        message: PropTypes.node,
        onClose: PropTypes.func,
        className: PropTypes.string,
    };

    useEffect(() => {
        function getFuelPrices() {
            axios.get(`https://www.fueleconomy.gov/ws/rest/fuelprices`, {
                timeout: 8000,
                headers: { 'Content-Type': 'application/json' }
            })
                .then(response => {
                    const details = response.data;
                    setGasPrices(details);
                })
                .catch(function (error) {
                    console.log(error);
                });
        }

        if (gasPrices === null) {
            getFuelPrices();
        } else {
            scenarioInputs.gasolinePrice = gasPrices.regular;
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gasPrices]);

    const handleNextClick = () => {
        if (scenarioInputs.state !== '') {
            setProfileExpanded(false);
            dispatch({
                type: CALC_TABLE_EXPAND,
                calcTableExpand: true
            })
        } else {
            setSnackBarOpen(true);
        }
    };
    const handleGetStartedClick = () => {
        setIntroExpanded(false);
        setProfileExpanded(true);
    };

    useEffect(() => {
        function getElectricityPrice() {
            axios.get(`https://api.eia.gov/series/?api_key=${API_KEY}&series_id=ELEC.PRICE.${STATE_DATA[scenarioInputs.state].postal}-COM.M`, {
                timeout: 8000,
                headers: { 'Content-Type': 'application/json' }
            })
                .then(response => {
                    const details = response.data.series[0].data;
                    setElecPrice((details[0][1] / 100).toPrecision(3))
                })
                .catch(function (error) {
                    console.log(error);
                });
        }

        if (STATE_DATA[scenarioInputs.state]) {
            getElectricityPrice();
            scenarioInputs.electricityPrice = elecPrice;

            dispatch({
                type: SCENARIO_INPUTS,
                scenarioInputs: scenarioInputs
            })
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scenarioInputs.state, elecPrice]);

    useEffect(() => {
        function calculateDailyMileage() {
            if (scenarioInputs.annualMileage !== undefined && scenarioInputs.weeksDrivenPerYear !== undefined && scenarioInputs.daysDrivenPerWeek !== undefined &&  scenarioInputs.weeksDrivenPerYear > 0 && scenarioInputs.daysDrivenPerWeek > 0) {
                scenarioInputs.dailyMileage = (scenarioInputs.annualMileage / (scenarioInputs.weeksDrivenPerYear * scenarioInputs.daysDrivenPerWeek)).toFixed(2).replace(".00", "");
            } else {
                scenarioInputs.dailyMileage = 0;
            }
            dispatch({
                type: SCENARIO_INPUTS,
                scenarioInputs: scenarioInputs
            })
        }

        calculateDailyMileage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scenarioInputs.weeksDrivenPerYear, scenarioInputs.daysDrivenPerWeek, scenarioInputs.annualMileage]);


    const handleProfileExpand = (event) => {
        event.preventDefault();
        setProfileExpanded(!profileExpanded);
    };
    const handleIntroExpand = (event) => {
        event.preventDefault();
        setIntroExpanded(!introExpanded);
    };

    function handleClose() {
        setSnackBarOpen(false);
    }

    return (
        <div>
        <div className={classes.root}>
                <ExpansionPanel expanded={introExpanded} onChange={(event) => handleIntroExpand(event)}>
                <ExpansionPanelSummary className={classes.toolbar} expandIcon={<ExpandMoreIcon />}>
                        <Typography variant='h2'>Choose Your State</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Grid container>
                        <Grid item xs>
                            <Grid container justify="center" alignItems='center'>
                                <Grid container direction="row" justify="center" alignItems="flex-start">
                                    <Grid container item direction="column" md={12} lg={12}>
                                   
                                            <Typography variant='body1' className={classes.label}>
                                                <br/>
                                           Learn the benefits of driving electric vehicles for yourself, your business, and the environment. 
                                            Using trusted reference data and  <AssumptionsDialog />, this tool can help you understand whether an electric car or fleet fits your situation, how much money you can save, and the emissions you can avoid. 
                                            Simply choose your state, adjust the details to fit you, and select the vehicles you would like to compare.
                                         
                                        </Typography>
                                    </Grid>
                                    </Grid>
                                    <Grid container direction="row" justify="center" alignItems="flex-start" >
                                        <Grid className={classes.margin_Top} container item direction="column" justify="flex-start" md={12} lg={12}>
                                            <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                             
                                                <Grid container item direction="column" justify="flex-start" xs={8} sm={6} md={4} lg={4} xl={3}>
                                                    {/*State*/}
                                                    <Select
                                                        id="state"
                                                        value={scenarioInputs.state}
                                                        className={classes.margin_Bottom}
                                                        displayEmpty
                                                        autoWidth
                                                        onChange={(event) => {
                                                            scenarioInputs.state = event.target.value;
                                                            scenarioInputs.salesTax = STATE_DATA[event.target.value].combinedTaxRate;
                                                            dispatch({
                                                                type: SCENARIO_INPUTS,
                                                                scenarioInputs: scenarioInputs
                                                            });
                                                        }}
                                                        aria-describedby="state-helper-text"
                                                        style={scenarioInputs.state === '' ? { color: 'rgb(153, 0, 0)' } : { color: 'currentColor' }}
                                                        inputProps={{
                                                            'aria-label': 'State',
                                                        }}>
                                                        <MenuItem key={"state_select"} value="" disabled
                                                            className={classes.menuItem}><em>Select a state</em></MenuItem>
                                                        {Object.keys(STATE_DATA).map(state => (
                                                            <MenuItem
                                                                key={state}
                                                                value={state}
                                                                className={classes.menuItem}>
                                                                {state}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    <Button variant='contained' color="primary" className='margin'
                                                        onClick={handleGetStartedClick}>
                                                        Get Started
                                                    </Button>
                                                   
                                                </Grid>
                                            </Grid>
                                            <Typography variant='body1' className={classes.label}>
                                                <br/>
                                                We provide this tool as a service to help people understand if electric vehicles may be right for them. The information provided are estimates and are not a guarantee of savings in any way.
                                           
                                                    </Typography>
                                        </Grid>
                                    </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>
        <div className={classes.root}>
                <ExpansionPanel expanded={profileExpanded} onChange={(event) => handleProfileExpand(event)}>
                <ExpansionPanelSummary className={classes.toolbar} expandIcon={<ExpandMoreIcon />}>
                        <Typography variant='h2'>Your Driving Profile</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Grid container>
                        <Grid item xs>
                            <Grid container justify="center" alignItems='center'>
                                <Grid  container direction="row" justify="center" alignItems="flex-start" >
                                    <Grid className={classes.margin_2} container item direction="column" justify="flex-start" md={12} lg={12}>
                                        <Grid  container direction="row" justify="flex-start" alignItems="flex-start">
                                            <Grid container item direction="column" justify="flex-start" xs={8} sm={6} md={4} lg={4} xl={3}>
                                                {/*State*/}
                                                <Select
                                                    id="state"
                                                    value={scenarioInputs.state}
                                                    className={classes.margin_Bottom}
                                                    displayEmpty
                                                    autoWidth
                                                    onChange={(event) => {
                                                        scenarioInputs.state = event.target.value;
                                                        scenarioInputs.salesTax = STATE_DATA[event.target.value].combinedTaxRate;
                                                        dispatch({
                                                            type: SCENARIO_INPUTS,
                                                            scenarioInputs: scenarioInputs
                                                        });
                                                    }}
                                                    aria-describedby="state-helper-text"
                                                    style={scenarioInputs.state === '' ? { color: 'rgb(153, 0, 0)' } : { color: 'currentColor' }}
                                                    inputProps={{
                                                        'aria-label': 'State',
                                                    }}>
                                                    <MenuItem key={"state_select"} value="" disabled
                                                        className={classes.menuItem}><em>Choose State</em></MenuItem>
                                                    {Object.keys(STATE_DATA).map(state => (
                                                        <MenuItem
                                                            key={state}
                                                            value={state}
                                                            className={classes.menuItem}>
                                                            {state}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container direction="row" justify="center" alignItems="flex-start" spacing={4}>
                                    <Grid container item direction="column" xs={12} sm={6} md={6} lg={6}  spacing={2} >
                                            <Grid container item direction="row" justify="space-between" alignItems="center" className={classes.backgroundLight}>
                                            <Grid container item direction="column" justify="flex-start" alignItems="flex-start" xs={12} sm={12} md={6} lg={6}>
                                                <Typography variant="subtitle1" className={classes.label}>
                                                    Mileage</Typography>
                                                {/*Annual Mileage*/}
                                            </Grid>
                                            <Grid container item direction="column" justify="flex-end" alignItems="flex-start" xs={12} sm={12} md={6} lg={6}>
                                                <NumberFormat
                                                    id="annual-mileage"
                                                    value={scenarioInputs.annualMileage}
                                                        className={classes.margin}
                                                        defaultValue={15000}
                                                        onValueChange={(event) => {
                                                           
                                                        scenarioInputs.annualMileage = event.value;
                                                        dispatch({
                                                            type: SCENARIO_INPUTS,
                                                            scenarioInputs: scenarioInputs
                                                        })
                                                        }}
                                                        thousandSeparator={true}
                                                        onFocus={(event) => {
                                                            event.target.select();
                                                        }}
                                                        isAllowed={(event) => {
                                                            return event.value >= 0
                                                        }}
                                                        onBlur={(event) => {
                                                            if (event.target.value === undefined || event.target.value === '' || event.target.value === 0) {
                                                                event.target.value = 15000;
                                                                scenarioInputs.annualMileage = event.target.value;
                                                                dispatch({
                                                                    type: SCENARIO_INPUTS,
                                                                    scenarioInputs: scenarioInputs
                                                                })
                                                            }
                                                            
                                                        }}
                                                    allowNegative={false}
                                                    decimalScale={0}
                                                    customInput={Input}
                                                    aria-describedby="annual-mileage-helper-text"
                                                    endAdornment={<InputAdornment position="end">mi/yr</InputAdornment>}
                                                    inputProps={{
                                                        'aria-label': 'Annual Mileage',
                                                    }}
                                                    />
                                            </Grid>
                                        </Grid>
                                            <Grid container item direction="row" justify="space-between" alignItems="center" className={classes.backgroundLight}>
                                            <Grid container item direction="column" justify="flex-start" alignItems="flex-start" xs={12} sm={12} md={6} lg={6}>
                                            <Typography variant="subtitle1" className={classes.label}>Days Driven</Typography>
                                            </Grid>
                                            {/*Days Driven Per Week*/}
                                            <Grid container item direction="column" justify="flex-end" alignItems="flex-start" xs={12} sm={12} md={6} lg={6}>
                                                <NumberFormat
                                                    id="days-driven-per-week"
                                                    value={scenarioInputs.daysDrivenPerWeek}
                                                    className={classes.margin}
                                                    onValueChange={(event) => {
                                                        scenarioInputs.daysDrivenPerWeek = event.value;
                                                        dispatch({
                                                            type: SCENARIO_INPUTS,
                                                            scenarioInputs: scenarioInputs
                                                        })
                                                        }}
                                                        isAllowed={(event) => {
                                                            return event.value < 8 && event.value >= 0
                                                        }}
                                                        onFocus={(event) => {
                                                            event.target.select();
                                                        }}
                                                        onBlur={(event) => {
                                                            if (event.target.value === undefined || event.target.value === '' || event.target.value === 0) {
                                                                event.target.value = 5;
                                                                scenarioInputs.daysDrivenPerWeek = event.target.value;
                                                                dispatch({
                                                                    type: SCENARIO_INPUTS,
                                                                    scenarioInputs: scenarioInputs
                                                                })
                                                            }
                                                           
                                                        }}
                                                        allowNegative={false}
                                                    decimalScale={0}
                                                    customInput={Input}
                                                    aria-describedby="days-driven-per-week-helper-text"
                                                    endAdornment={<InputAdornment position="end">days/wk</InputAdornment>}
                                                    inputProps={{
                                                        'aria-label': 'Days Driven Per Week',
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                            <Grid container item direction="row" justify="space-between" alignItems="center" className={classes.backgroundLight}>
                                            <Grid container item direction="column" justify="flex-start" alignItems="flex-start" xs={12} sm={12} md={6} lg={6}>
                                                <Typography variant="subtitle1" className={classes.label}>Weeks Driven</Typography>
                                            </Grid>
                                            {/*Weeks Driven Per Year*/}
                                            <Grid container item direction="column" justify="flex-end" alignItems="flex-start" xs={12} sm={12} md={6} lg={6}>
                                                <NumberFormat
                                                    id="weeks-driven-per-year"
                                                    value={scenarioInputs.weeksDrivenPerYear}
                                                    className={classes.margin}
                                                    onValueChange={(event) => {
                                                        scenarioInputs.weeksDrivenPerYear = event.value;
                                                        dispatch({
                                                            type: SCENARIO_INPUTS,
                                                            scenarioInputs: scenarioInputs
                                                        })
                                                        }}
                                                        isAllowed={(event) => {
                                                            return event.value < 53 && event.value >= 0
                                                        }}
                                                        onFocus={(event) => {
                                                            event.target.select();
                                                        }}
                                                        onBlur={(event) => {
                                                            if (event.target.value === undefined || event.target.value === '' || event.target.value === 0) {
                                                                event.target.value = 50;
                                                                scenarioInputs.weeksDrivenPerYear = event.target.value;
                                                                dispatch({
                                                                    type: SCENARIO_INPUTS,
                                                                    scenarioInputs: scenarioInputs
                                                                })
                                                            }
                                                           
                                                        }}
                                                        allowNegative={false}
                                                    decimalScale={0}
                                                    customInput={Input}
                                                    aria-describedby="weeks-driven-per-year-helper-text"
                                                    endAdornment={<InputAdornment position="end">wks/yr</InputAdornment>}
                                                    inputProps={{
                                                        'aria-label': 'Weeks Driven Per Year',
                                                    }}
                                                />
                                             </Grid>
                                            </Grid>
                                            <Grid container item direction="row" justify="space-between" alignItems="center" className={classes.backgroundLight}>
                                                <Grid container item direction="column" justify="flex-start" alignItems="flex-start" xs={6} sm={6} md={6} lg={6}>
                                                <Typography variant="subtitle1" className={classes.label}>Daily
                                                    Mileage</Typography>
                                            </Grid>
                                            {/*Daily Mileage*/}
                                                <Grid container item direction="column" justify="flex-end" alignItems="flex-start" xs={6} sm={6} md={6} lg={6}>
                                                    <Typography variant="body1" className={classes.label}><b>{scenarioInputs.dailyMileage}</b> mi</Typography>
                                                
                                            </Grid>
                                        </Grid>
                                        <Grid container item direction="row" justify="space-between" alignItems="center">
                                            <Grid container item direction="column" justify="flex-start" alignItems="flex-start" xs={12} sm={12} md={6} lg={6}>
                                                <Typography variant="subtitle1" className={classes.label}>Sales
                                                    Tax</Typography>
                                            </Grid>
                                            {/*Sales Tax*/}
                                            <Grid container item direction="column" justify="flex-end" alignItems="flex-start" xs={12} sm={12} md={6} lg={6}>
                                                <NumberFormat
                                                    id="sales-tax"
                                                    value={parseFloat((scenarioInputs.salesTax * 100).toFixed(2))}
                                                    className={classes.margin}
                                                    onValueChange={(values) => {
                                                        scenarioInputs.salesTax = values.value / 100;
                                                        dispatch({
                                                            type: SCENARIO_INPUTS,
                                                            scenarioInputs: scenarioInputs
                                                        })
                                                        }}
                                                        onFocus={(event) => {
                                                            event.target.select();
                                                        }}
                                                        isAllowed={(event) => {
                                                            return event.value >= 0 && event.value < 101
                                                        }}
                                                        allowNegative={false}
                                                    aria-describedby="sales-tax-helper-text"
                                                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                                    customInput={Input}
                                                    inputProps={{
                                                        'aria-label': 'Sales Tax',
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container item direction="row" justify="space-between" alignItems="center">
                                            <Grid container item direction="column" justify="flex-start" alignItems="flex-start" xs={12} sm={12} md={6} lg={6}>
                                                <Typography variant="subtitle1" className={classes.label}>Gas Price
                                                </Typography>
                                            </Grid>
                                            {/*Gasoline Price*/}
                                            <Grid container item direction="column" justify="flex-end" alignItems="flex-start" xs={12} sm={12} md={6} lg={6}>
                                                <NumberFormat
                                                    id="gasoline-price"
                                                    value={scenarioInputs.gasolinePrice}
                                                    className={classes.margin}
                                                    onValueChange={(values) => {
                                                        scenarioInputs.gasolinePrice = values.value;
                                                        dispatch({
                                                            type: SCENARIO_INPUTS,
                                                            scenarioInputs: scenarioInputs
                                                        });
                                                        }}
                                                        allowNegative={false}
                                                        decimalScale={2}
                                                        onFocus={(event) => {
                                                            event.target.select();
                                                        }}
                                                        isAllowed={(event) => {
                                                            return event.value >= 0 && event.value < 101
                                                        }}
                                                        onBlur={(event) => {
                                                            if (event.target.value === undefined || event.target.value === '' || event.target.value === 0) {
                                                                event.target.value = 2.65;
                                                                scenarioInputs.gasolinePrice = event.target.value;
                                                                dispatch({
                                                                    type: SCENARIO_INPUTS,
                                                                    scenarioInputs: scenarioInputs
                                                                })
                                                            }
                                                           
                                                        }}
                                                    aria-describedby="gasoline-price-helper-text"
                                                    endAdornment={<InputAdornment
                                                        position="end">/gallon</InputAdornment>}
                                                    customInput={Input}
                                                    prefix="$"
                                                    inputProps={{
                                                        'aria-label': 'Gasoline Price',
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                       <Grid container item direction="row" justify="space-between" alignItems="center">
                                            <Grid container item direction="column" justify="flex-start" alignItems="flex-start" xs={12} sm={12} md={6} lg={6}>
                                                <div style={{display:"flex"}}>
                                                <Typography variant="subtitle1" className={classes.label}>Electricity
                                                    Price
                                                    ($/kWh)</Typography><InfoButton infoText={"Representative electricity rate. \n Your actual rate could be as little as one-third of this amount, check your bill to verify."} /></div>
                                            </Grid>
                                            {/*Electricity Price*/}
                                            <Grid container item direction="column" justify="flex-end" alignItems="flex-start" xs={12} sm={12} md={6} lg={6}>
                                                <NumberFormat
                                                    id="electricity-price"
                                                    value={scenarioInputs.electricityPrice}
                                                    className={classes.margin}
                                                    onValueChange={(values) => {
                                                        scenarioInputs.electricityPrice = values.value;
                                                        dispatch({
                                                            type: SCENARIO_INPUTS,
                                                            scenarioInputs: scenarioInputs
                                                        })
                                                        }}
                                                        onFocus={(event) => {
                                                            event.target.select();
                                                        }}
                                                        isAllowed={(event) => {
                                                            return event.value >= 0 && event.value < 101
                                                        }}
                                                        onBlur={(event) => {
                                                            if (event.target.value === undefined || event.target.value === '') {
                                                                event.target.value = 0;
                                                                scenarioInputs.electricityPrice = event.target.value;
                                                                dispatch({
                                                                    type: SCENARIO_INPUTS,
                                                                    scenarioInputs: scenarioInputs
                                                                })
                                                            }
                                                        }}
                                                        allowNegative={false}
                                                    aria-describedby="electricity-price-helper-text"
                                                    customInput={Input}
                                                    prefix="$ "
                                                    endAdornment={<InputAdornment position="end">/kWh</InputAdornment>}
                                                    inputProps={{
                                                        'aria-label': 'Electricity Price',
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        
                                       
                                    </Grid>
                                    <Grid container item direction="column" xs={12} sm={6} md={6} lg={6} spacing={2}>
                                        <Grid container item direction="row" justify="space-between" alignItems="center">
                                            <Grid container item direction="column" justify="flex-start" alignItems="flex-start" xs={12} sm={12} md={5} lg={5}>
                                                <Typography variant="subtitle1" className={classes.label}>Finance Type</Typography>
                                            </Grid>
                                            {/*Purchase Lease Period*/}
                                            <Grid container item direction="column" justify="flex-end" alignItems="flex-start" xs={12} sm={12} md={7} lg={7}>      
                                                <Select
                                                    id="financeType"
                                                    value={scenarioInputs.financeType}
                                                    className={classes.margin}
                                                    displayEmpty
                                                    autoWidth
                                                    onChange={(event) => {
                                                        scenarioInputs.financeType = event.target.value;
                                                        if (scenarioInputs.financeType === "Lease") {
                                                            scenarioInputs.useResidualValue = true;
                                                        }
                                                        dispatch({
                                                            type: SCENARIO_INPUTS,
                                                            scenarioInputs: scenarioInputs
                                                        });
                                                    }}
                                                    aria-describedby="state-helper-text"
                                                    inputProps={{
                                                        'aria-label': 'Finance Type',
                                                    }}>
                                                    <MenuItem
                                                        key='Lease'
                                                        value='Lease'
                                                        className={classes.menuItem}>
                                                        Lease
                                                    </MenuItem>
                                                    <MenuItem
                                                        key='Purchase'
                                                        value='Purchase'
                                                        className={classes.menuItem}>
                                                        Purchase
                                                    </MenuItem>
                                                </Select>
                                            </Grid>
                                        </Grid>
                                        <Grid container item direction="row" justify="space-between" alignItems="center">
                                            <Grid container item direction="column" justify="flex-start" alignItems="flex-start" xs={12} sm={12} md={5} lg={5}>
                                               
                                            </Grid>
                                            {/*Residual Value*/}
                                            <Grid container item direction="column" justify="flex-start" alignItems="flex-start" xs={12} sm={12} md={7} lg={7}>
                                            <FormControlLabel
                                                control={<Checkbox
                                                    id="residual-value"
                                                    className={classes.margin}
                                                    onChange={(event) => {
                                                        scenarioInputs.useResidualValue = event.target.checked;
                                                        dispatch({
                                                            type: SCENARIO_INPUTS,
                                                            scenarioInputs: scenarioInputs
                                                        })
                                                    }}
                                                    disabled={scenarioInputs.financeType === 'Lease'}
                                                    checked={scenarioInputs.useResidualValue }
                                                    aria-describedby="residual-value-helper-text"
                                                    inputProps={{
                                                        'aria-label': 'Use Residual Value',
                                                    }}
                                                    
                                                />}
                                                label="Use Residual Value" />
                                            </Grid>
                                        </Grid>
                                        
                                        <Grid container item direction="row" justify="space-between" alignItems="center">
                                            <Grid container item direction="column" justify="flex-start" alignItems="flex-start" xs={12} sm={12} md={5} lg={5}>
                                                <Typography variant="subtitle1" className={classes.label}>Finance Period</Typography>
                                            </Grid>
                                            {/*Purchase Lease Period*/}
                                            <Grid container item direction="column" justify="flex-end" alignItems="flex-start" xs={12} sm={12} md={7} lg={7}>      
                                                <NumberFormat
                                                    id="purchase-lease-period"
                                                    value={scenarioInputs.purchaseLeasePeriod}
                                                    className={classes.margin}
                                                    onValueChange={(event) => {
                                                        scenarioInputs.purchaseLeasePeriod = event.value;
                                                        dispatch({
                                                            type: SCENARIO_INPUTS,
                                                            scenarioInputs: scenarioInputs
                                                        })
                                                    }}
                                                        isAllowed={(event) => {
                                                            return event.value < 11 && event.value >= 0
                                                        }}
                                                        onFocus={(event) => {
                                                            event.target.select();
                                                        }}
                                                        onBlur={(event) => {
                                                            if (event.target.value === undefined || event.target.value === '' || event.target.value === 0) {
                                                                event.target.value = 1;
                                                                scenarioInputs.purchaseLeasePeriod = event.target.value;
                                                                dispatch({
                                                                    type: SCENARIO_INPUTS,
                                                                    scenarioInputs: scenarioInputs
                                                                })
                                                            }
                                                            else if(event.target.value > parseInt(scenarioInputs.ownershipPeriod)){
                                                                scenarioInputs.ownershipPeriod = event.target.value;
                                                                 dispatch({
                                                                    type: SCENARIO_INPUTS,
                                                                    scenarioInputs: scenarioInputs
                                                                }) 
                                                            }
                                                        }}
                                                        allowNegative={false}
                                                    decimalScale={0}
                                                    customInput={Input}
                                                    aria-describedby="purchase-lease-period-helper-text"
                                                    endAdornment={<InputAdornment position="end">years</InputAdornment>}
                                                    inputProps={{
                                                        'aria-label': 'Purchase/Lease Period',
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                      
                                        
                                        
                                        <Grid container item direction="row" justify="space-between" alignItems="center">
                                            <Grid container item direction="column" justify="flex-start" alignItems="flex-start" xs={12} sm={12} md={5} lg={5}>
                                                <Typography variant="subtitle1" className={classes.label}>Finance Rate</Typography>
                                            </Grid>
                                            {/*Purchase Lease Rate*/}
                                            <Grid container item direction="column" justify="flex-end" alignItems="flex-start" xs={12} sm={12} md={7} lg={7}>
                                                <NumberFormat
                                                    id="purchase-lease-rate"
                                                    value={(scenarioInputs.purchaseLeaseRate)}
                                                    className={classes.margin}
                                                        onValueChange={(event) => {
                                                            scenarioInputs.purchaseLeaseRate = event.value;
                                                        dispatch({
                                                            type: SCENARIO_INPUTS,
                                                            scenarioInputs: scenarioInputs
                                                        })
                                                        }}
                                                        isAllowed={(event) => {
                                                            return event.value < 101 && event.value >= 0
                                                        }}
                                                        onFocus={(event) => {
                                                            event.target.select();
                                                        }}
                                                        onBlur={(event) => {
                                                            if (event.target.value === undefined || event.target.value === '') {
                                                                event.target.value = 0;
                                                                scenarioInputs.purchaseLeaseRate = event.target.value;
                                                                dispatch({
                                                                    type: SCENARIO_INPUTS,
                                                                    scenarioInputs: scenarioInputs
                                                                })
                                                            }
                                                           
                                                        }}
                                                        allowNegative={false}
                                                    aria-describedby="purchase-lease-rate-helper-text"
                                                    customInput={Input}
                                                    suffix="%"
                                                    endAdornment={<InputAdornment
                                                        position="end">annually</InputAdornment>}
                                                    inputProps={{
                                                        'aria-label': 'Purchase/Lease Rate',
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container item direction="row" justify="space-between" alignItems="center">
                                            <Grid container item direction="column" justify="flex-start" alignItems="flex-start" xs={12} sm={12} md={5} lg={5}>
                                                <Typography variant="subtitle1" className={classes.label}>Discount Rate</Typography>
                                            </Grid>
                                            {/*Discount Rate*/}
                                            <Grid container item direction="column" justify="flex-end" alignItems="flex-start" xs={12} sm={12} md={7} lg={7}>
                                                <NumberFormat
                                                    id="discount-rate"
                                                    value={(scenarioInputs.discountRate)}
                                                    decimalScale={2}
                                                    className={classes.margin}
                                                        onValueChange={(event) => {
                                                            scenarioInputs.discountRate = event.value;
                                                        dispatch({
                                                            type: SCENARIO_INPUTS,
                                                            scenarioInputs: scenarioInputs
                                                        })
                                                        }}
                                                        onFocus={(event) => {
                                                            event.target.select();
                                                        }}
                                                        isAllowed={(event) => {
                                                            return event.value < 101 && event.value >= 0
                                                        }}
                                                        onBlur={(event) => {
                                                            if (event.target.value === undefined || event.target.value === '') {
                                                                event.target.value = 0;
                                                                scenarioInputs.discountRate = event.target.value;
                                                                dispatch({
                                                                    type: SCENARIO_INPUTS,
                                                                    scenarioInputs: scenarioInputs
                                                                })
                                                            }
                                                           
                                                        }}
                                                        allowNegative={false}
                                                    aria-describedby="discount-rate-helper-text"
                                                    customInput={Input}
                                                    suffix="%"
                                                    endAdornment={<InputAdornment
                                                        position="end">annually</InputAdornment>}
                                                    inputProps={{
                                                        'aria-label': 'Discount Rate',
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container item direction="row" justify="space-between" alignItems="center">
                                            <Grid container item direction="column" justify="flex-start" alignItems="flex-start" xs={12} sm={12} md={5} lg={5}>
                                                <Typography variant="subtitle1" className={classes.label}>Ownership
                                                Period</Typography>
                                            </Grid>
                                            {/*Ownership Period*/}
                                            <Grid container item direction="column" justify="flex-end" alignItems="flex-start" xs={12} sm={12} md={7} lg={7}>
                                                <NumberFormat
                                                    id="ownership-period"
                                                    value={scenarioInputs.ownershipPeriod}
                                                    className={classes.margin}
                                                        onValueChange={(event) => {
                                                            scenarioInputs.ownershipPeriod = event.value;
                                                            
                                                            dispatch({
                                                                type: SCENARIO_INPUTS,
                                                                scenarioInputs: scenarioInputs
                                                            })
                                                        }}
                                                        onFocus={(event) => {
                                                            event.target.select();
                                                        }}
                                                        onBlur={(event) => {
                                                            if (event.target.value === undefined || event.target.value === '' || event.target.value === 0) {
                                                                event.target.value = 10;
                                                                scenarioInputs.ownershipPeriod = event.target.value;
                                                                dispatch({
                                                                    type: SCENARIO_INPUTS,
                                                                    scenarioInputs: scenarioInputs
                                                                })
                                                            }
                                                            if(scenarioInputs.ownershipPeriod < scenarioInputs.purchaseLeasePeriod){
                                                                scenarioInputs.purchaseLeasePeriod = event.target.value;
                                                                dispatch({
                                                                    type: SCENARIO_INPUTS,
                                                                    scenarioInputs: scenarioInputs
                                                                })
                                                            }
                                                        }}
                                                    isAllowed={(event) => {
                                                        return event.value < 11 && event.value >= 0
                                                        }}
                                                        allowNegative={false}
                                                        decimalScale={0}
                                                        defaultValue={0}
                                                    customInput={Input}
                                                    aria-describedby="ownership-period-helper-text"
                                                    endAdornment={<InputAdornment position="end">years</InputAdornment>}
                                                    inputProps={{
                                                        'aria-label': 'Ownership Period',
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    </Grid>
                                    <Grid container direction="row" justify="center" alignItems="flex-start" >
                                        <Grid container item direction="column" justify="flex-start" md={12} lg={12}>
                                            <div>
                                            <Button variant='contained' color="primary" className={classes.margin_Top}
                                            onClick={handleNextClick}>
                                            Select Cars
                                            <NextIcon />
                                                </Button>
                                            </div>
                                        </Grid>
                                    </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </ExpansionPanelDetails>
                    <Snackbar
                    open={snackBarOpen}
                        autoHideDuration={3500}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        className={classes.topFix}
                    TransitionComponent={GrowTransition}>
                    <MySnackbarContentWrapper message={'State Selection Required to Continue.'}
                        onClose={handleClose} />
                </Snackbar>
            </ExpansionPanel>
            </div>
            </div>
    );
};
