export const SCENARIO_INPUTS = 'SCENARIO_INPUTS';
export const VEHICLE_YEARS = 'VEHICLE_YEARS';
export const VEHICLES_LIST = 'VEHICLES_LIST';
export const VEHICLE_SELECTION_DETAILS = 'VEHICLE_SELECTION_DETAILS';
export const ADD_NEW_VEHICLE_SHOWING = 'ADD_NEW_VEHICLE_SHOWING';
export const CALCULATIONS = 'CALCULATIONS';
export const CALC_TABLE_EXPAND = 'CALC_TABLE_EXPAND';

export const API_KEY = 'b5ad6cd1985a4e509b944242d892e3af';

/*******
 * Data
 *******/
export const STATES_ABB = {
    AL: 'Alabama',
    AK: 'Alaska',
    AZ: 'Arizona',
    AR: 'Arkansas',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DE: 'Delaware',
    FL: 'Florida',
    GA: 'Georgia',
    HI: 'Hawaii',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    IA: 'Iowa',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    ME: 'Maine',
    MD: 'Maryland',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MN: 'Minnesota',
    MS: 'Mississippi',
    MO: 'Missouri',
    MT: 'Montana',
    NE: 'Nebraska',
    NV: 'Nevada',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NY: 'New York',
    NC: 'North Carolina',
    ND: 'North Dakota',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PA: 'Pennsylvania',
    PR: 'Puerto Rico',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VT: 'Vermont',
    VA: 'Virginia',
    WA: 'Washington',
    WV: 'West Virginia',
    WI: 'Wisconsin',
    WY: 'Wyoming'
};

export const EXCLUDED_MAKES = ['Aston Martin', 'Bentley', 'Bugatti', 'Ferrari', 'Jaguar', 'Koenigsegg', 'Lamborghini', 'Land Rover', 'Lotus', 'Maserati', 'McLaren Automotive', 'Pagani', 'Porsche', 'Rolls-Royce', 'Roush Performance', 'RUF Automobile'];

export const STATE_DATA = {
    //'Alabama': {
    //    'combinedTaxRate': .0915,
    //    'electricEmissionRate': 395.2947846,
    //    'postal': 'AL'
    //},
    //'Alaska': {
    //    'combinedTaxRate': .0143,
    //    'electricEmissionRate': 400.6802721,
    //    'postal': 'AK'
    //},
    //'Arizona': {
    //    'combinedTaxRate': .0838,
    //    'electricEmissionRate': 403.739229,
    //    'postal': 'AZ'
    //},
    'Arkansas': {
        'combinedTaxRate': .0942,
        'electricEmissionRate': 483.6598639,
        'postal': 'AR'
    },
    //'California': {
    //    'combinedTaxRate': .0855,
    //    'electricEmissionRate': 195.6439909,
    //    'postal': 'CA'
    //},
    //'Colorado': {
    //    'combinedTaxRate': .0752,
    //    'electricEmissionRate': 636.7369615,
    //    'postal': 'CO'
    //},
    //'Connecticut': {
    //    'combinedTaxRate': .0635,
    //    'electricEmissionRate': 216.324263,
    //    'postal': 'CT'
    //},
    //'Delaware': {
    //    'combinedTaxRate': .0000,
    //    'electricEmissionRate': 383.1451247,
    //    'postal': 'DE'
    //},
    //'Florida': {
    //    'combinedTaxRate': .0680,
    //    'electricEmissionRate': 443.3333333,
    //    'postal': 'FL'
    //},
    //'Georgia': {
    //    'combinedTaxRate': .0723,
    //    'electricEmissionRate': 434.0272109,
    //    'postal': 'GA'
    //},
    //'Hawaii': {
    //    'combinedTaxRate': .0435,
    //    'electricEmissionRate': 660.4331066,
    //    'postal': 'HI'
    //},
    //'Idaho': {
    //    'combinedTaxRate': .0603,
    //    'electricEmissionRate': 81.55782313,
    //    'postal': 'ID'
    //},
    //'Illinois': {
    //    'combinedTaxRate': .0873,
    //    'electricEmissionRate': 351.5646259,
    //    'postal': 'IL'
    //},
    'Indiana': {
        'combinedTaxRate': .0700,
        'electricEmissionRate': 786.2380952,
        'postal': 'IN'
    },
    //'Iowa': {
    //    'combinedTaxRate': .0682,
    //    'electricEmissionRate': 432.5192744,
    //    'postal': 'IA'
    //},
    //'Kansas': {
    //    'combinedTaxRate': .0868,
    //    'electricEmissionRate': 518.7732426,
    //    'postal': 'KS'
    //},
    'Kentucky': {
        'combinedTaxRate': .0600,
        'electricEmissionRate': 847.9342404,
        'postal': 'KY'
    },
    'Louisiana': {
        'combinedTaxRate': .0945,
        'electricEmissionRate': 380.0430839,
        'postal': 'LA'
    },
    //'Maine': {
    //    'combinedTaxRate': .0550,
    //    'electricEmissionRate': 149.7596372,
    //    'postal': 'ME'
    //},
    //'Maryland': {
    //    'combinedTaxRate': .0600,
    //    'electricEmissionRate': 439.2403628,
    //    'postal': 'MD'
    //},
    //'Massachusetts': {
    //    'combinedTaxRate': .0625,
    //    'electricEmissionRate': 356.5192744,
    //    'postal': 'MA'
    //},
    'Michigan': {
        'combinedTaxRate': .0600,
        'electricEmissionRate': 476.5079365,
        'postal': 'MI'
    },
    //'Minnesota': {
    //    'combinedTaxRate': .0743,
    //    'electricEmissionRate': 439.585034,
    //    'postal': 'MN'
    //},
    //'Mississippi': {
    //    'combinedTaxRate': .0707,
    //    'electricEmissionRate': 406.2811791,
    //    'postal': 'MS'
    //},
    //'Missouri': {
    //    'combinedTaxRate': .0808,
    //    'electricEmissionRate': 731.9954649,
    //    'postal': 'MO'
    //},
    //'Montana': {
    //    'combinedTaxRate': .0000,
    //    'electricEmissionRate': 542.9002268,
    //    'postal': 'MT'
    //},
    //'Nebraska': {
    //    'combinedTaxRate': .0689,
    //    'electricEmissionRate': 556.0839002,
    //    'postal': 'NE'
    //},
    //'Nevada': {
    //    'combinedTaxRate': .0814,
    //    'electricEmissionRate': 332.3922902,
    //    'postal': 'NV'
    //},
    //'New Hampshire': {
    //    'combinedTaxRate': .0000,
    //    'electricEmissionRate': 136.4897959,
    //    'postal': 'NH'
    //},
    //'New Jersey': {
    //    'combinedTaxRate': .0660,
    //    'electricEmissionRate': 241.1836735,
    //    'postal': 'NJ'
    //},
    //'New Mexico': {
    //    'combinedTaxRate': .0778,
    //    'electricEmissionRate': 681.845805,
    //    'postal': 'NM'
    //},
    //'New York': {
    //    'combinedTaxRate': .0849,
    //    'electricEmissionRate': 200.7278912,
    //    'postal': 'NY'
    //},
    //'North Carolina': {
    //    'combinedTaxRate': .0695,
    //    'electricEmissionRate': 375.9931973,
    //    'postal': 'NC'
    //},
    //'North Dakota': {
    //    'combinedTaxRate': .0683,
    //    'electricEmissionRate': 721.569161,
    //    'postal': 'ND'
    //},
    'Ohio': {
        'combinedTaxRate': .0715,
        'electricEmissionRate': 635.61678,
        'postal': 'OH'
    },
    'Oklahoma': {
        'combinedTaxRate': .0893,
        'electricEmissionRate': 451.6485261,
        'postal': 'OK'
    },
    //'Oregon': {
    //    'combinedTaxRate': .0000,
    //    'electricEmissionRate': 132.3537415,
    //    'postal': 'OR'
    //},
    //'Pennsylvania': {
    //    'combinedTaxRate': .0634,
    //    'electricEmissionRate': 370.521542,
    //    'postal': 'PA'
    //},
    //'Rhode Island': {
    //    'combinedTaxRate': .0700,
    //    'electricEmissionRate': 375.5623583,
    //    'postal': 'RI'
    //},
    //'South Carolina': {
    //    'combinedTaxRate': .0743,
    //    'electricEmissionRate': 272.6349206,
    //    'postal': 'SC'
    //},
    //'South Dakota': {
    //    'combinedTaxRate': .0640,
    //    'electricEmissionRate': 222.5714286,
    //    'postal': 'SD'
    //},
    'Tennessee': {
        'combinedTaxRate': .0946,
        'electricEmissionRate': 430.1496599,
        'postal': 'TN'
    },
    'Texas': {
        'combinedTaxRate': .0817,
        'electricEmissionRate': 454.3628118,
        'postal': 'TX'
    },
    //'Utah': {
    //    'combinedTaxRate': .0678,
    //    'electricEmissionRate': 705.8866213,
    //    'postal': 'UT'
    //},
    //'Vermont': {
    //    'combinedTaxRate': .0618,
    //    'electricEmissionRate': 28.78004535,
    //    'postal': 'VT'
    //},
    'Virginia': {
        'combinedTaxRate': .0565,
        'electricEmissionRate': 352.8571429,
        'postal': 'VA'
    },
    //'Washington': {
    //    'combinedTaxRate': .0919,
    //    'electricEmissionRate': 80.95464853,
    //    'postal': 'WA'
    //},
    'West Virginia': {
        'combinedTaxRate': .0638,
        'electricEmissionRate': 857.6281179,
        'postal': 'WV'
    },
    //'Wisconsin': {
    //    'combinedTaxRate': .0544,
    //    'electricEmissionRate': 601.6666667,
    //    'postal': 'WI'
    //},
    //'Wyoming': {
    //    'combinedTaxRate': .0539,
    //    'electricEmissionRate': 879.3424036,
    //    'postal': 'WY'
    //}
};
