import Button from "@material-ui/core/Button";
import InfoIcon from "@material-ui/icons/Info";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import React, { useState } from "react";

const InfoButton = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : null;

    function showInfo(event) {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    }

    function handleModalClose() {
        setAnchorEl(null);
    }

    return (
        <div>
            <Button aria-describedby={id} onClick={(event) => showInfo(event)}>
                <InfoIcon />
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleModalClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Typography className='popover' style={{whiteSpace: "pre-line"}}>
                    {props.infoText}
                    {props.infoLink ? <Link href={props.infoLink} target='_blank' rel='noopener'>{props.infoLink}</Link> : ''}
                </Typography>
            </Popover>
        </div>
    )
};

export default InfoButton;