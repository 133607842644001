import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    }
});
const linkStyles = theme => ({
    root: {
        color: '#2499DE !important'
    }
});
const StyledLink = withStyles(linkStyles)(Link);
 
const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);


class AssumptionsDialog extends React.Component {
  state = {
    open: false,
  };

  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    return (
        <span>
            <StyledLink  onClick={this.handleClickOpen}  >
          informed assumptions
        </StyledLink>
        <Dialog
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
                open={this.state.open}
                maxWidth='md'
        >
          <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
            Assumptions
          </DialogTitle>
          <DialogContent dividers>
                <ul>
                    <li>Vehicle MSRP, efficiency, and gasoline emissions information from fueleconomy.gov</li>
                    <li>Assumes that conventional vehicles will achieve EPA efficiency, and do not degrade over time due to wear, aging, and maintenance</li>
                    <li>This tool will underestimate cost and emissions for conventional vehicles that are used in applications with more than 55% city driving or any idle time</li>
                    <li>Electricity emissions information from Energy Information Administration</li>
                    <li>Upstream gasoline emissions calculated based on <a href='https://www.carbonbrief.org/factcheck-how-electric-vehicles-help-to-tackle-climate-change' target='_blank' rel="noopener noreferrer">Carbon Brief</a> and  <a href='https://innovationorigins.com/producing-gasoline-and-diesel-emits-more-co2-than-we-thought/' target='_blank' rel="noopener noreferrer">Innovation Origins</a></li>
                    <li>Assumes 2.5% annual reduction in grid emissions</li>
                    <li>Does not include emissions or environmental impacts from the production, transportation, or use of engine oil</li>
                    <li>Assumes nominal annual price escalation of gasoline - 5%, electricity - 2.3%,  based on previous 20 years from <a href='https://www.eia.gov/outlooks/steo/realprices/' target='_blank' rel="noopener noreferrer">www.eia.gov/outlooks/steo/realprices/</a></li>
                    <li>Assumes that PHEVs and BEVs will be fully charged at the beginning of each day</li>
                    <li>Lease includes sales tax on payments; vehicle purchase includes sales tax on MSRP, included in payment</li>              
                    <li>When residual value is checked, it is calculated using an average estimate for all vehicles, rather than for each model</li>
                    <li>Maintenance costs calculated based on <a href="https://advocacy.consumerreports.org/press_release/electric-vehicle-owners-spending-half-as-much-on-maintenance-compared-to-gas-powered-vehicle-owners-finds-new-cr-analysis/" target="_blank">Consumer Reports</a></li>
                </ul>
          </DialogContent>
        </Dialog>
      </span>
    );
  }
}

export default AssumptionsDialog;
