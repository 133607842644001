import React, {useState} from 'react';
import {useStateValue} from "../context/store";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import MuiTableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import NumberFormat from 'react-number-format';
import './vehicleFinancialsTable.css';
import {Grid, withStyles} from "@material-ui/core";
import TableHead from "@material-ui/core/TableHead";
import CostOverLifeTimeChart from "./costOverLifeTimeChart";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button/Button";
import AddIcon from '@material-ui/icons/Add';
import MinusIcon from '@material-ui/icons/Remove'

const TableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
    sizeSmall: {
        padding: '8px'
    }
}))(MuiTableCell);

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
    },
    table: {
        minWidth: 400,
    },
    row: {
        backgroundColor: '#f3f3f3',
    },
    button: {
        margin: theme.spacing(4)
    }
}));

export default function VehicleFinancialsTable() {
    const classes = useStyles();
    const [{ calculations, scenarioInputs }] = useStateValue();
    const [showFinancialsTable, setShowFinancialsTable] = useState(false);
    const useResidualValue = scenarioInputs.useResidualValue;
    function createHeaders() {
        let headers = [<TableCell />, <TableCell />];
        if (scenarioInputs.ownershipPeriod !== undefined) {
            for (let i = 1; i <= scenarioInputs.ownershipPeriod; i++) {
                headers.push(
                    <TableCell size="small">
                        <Typography variant='subtitle2'>
                            {i.valueOf()}
                        </Typography>
                    </TableCell>
                );
            }
        }
        return headers;
    }

    const toggleTable = () => {
        setShowFinancialsTable(!showFinancialsTable);
    }

    function createBody(calc) {
        let body = [<TableCell size="small" scope={classes.row}>
            <div className='tableColumn'>
                {calc.financials.gas[0] === "N/A" || calc.financials.gas.length === 0 ? null : <Typography variant='subtitle2'>Gas</Typography>}
                {calc.financials.elec.length > 0 ? <Typography variant='subtitle2'>Electric</Typography> : null}
                {calc.financials.maint.length > 0 ? <Typography variant='subtitle2'>Maintenance</Typography> : null}
                <Typography variant='subtitle2'>Car Pmt</Typography>
                <Typography variant='subtitle2'>Cumulative</Typography>
            </div>
        </TableCell>];
        for (let i = 0; i < calc.financials.pmt.length; i++) {
            body.push(<TableCell size="small" scope={classes.row}>
                <div className='tableColumn'>
                    {calc.financials.gas[0] === "N/A" || calc.financials.gas.length === 0 ? null :
                        <Typography variant='subtitle2'>
                            <NumberFormat value={calc.financials.gas[i]} thousandSeparator={true} prefix={'$'} decimalScale={2}
                                displayType={'text'} />
                        </Typography>}
                    {calc.financials.elec.length > 0 ?
                        <Typography variant='subtitle2'>
                            <NumberFormat value={calc.financials.elec[i]} thousandSeparator={true} prefix={'$'} decimalScale={2}
                                displayType={'text'} />
                        </Typography> : null}
                    {calc.financials.maint.length > 0 ? <Typography variant='subtitle2'>
                        <NumberFormat value={calc.financials.maint[i]} thousandSeparator={true} prefix={'$'} decimalScale={2}
                            displayType={'text'} />
                    </Typography> : null}
                    <Typography variant='subtitle2'>
                        <NumberFormat value={calc.financials.pmt[i]} thousandSeparator={true} prefix={'$'} decimalScale={2}
                            displayType={'text'} />
                    </Typography>
                    <Typography variant='subtitle2'>
                        <NumberFormat value={calc.financials.cumulative[i]} thousandSeparator={true} prefix={'$'} decimalScale={2}
                            displayType={'text'} />
                    </Typography>
                </div>
            </TableCell>)
        }
        return body;
    }

    return (
        <Paper className='classes.root'>
            <Toolbar className='toolbar'>
                <Typography variant='h2' color="inherit">
                    Cost Over Lifetime
                </Typography>
                <Typography variant='subtitle2' color='inherit' style={{ marginLeft: '18px' }}>{!useResidualValue ? "(DOES NOT INCLUDE VEHICLE RESIDUAL VALUE)" : null}</Typography>
                <div className='growRow' />
            </Toolbar>
               
                <Grid container justify="center">
                    <Grid container item direction="row" lg={12}>
                        <CostOverLifeTimeChart />
                    </Grid>
                    {showFinancialsTable? 
                    <Grid container item direction="row" lg={12} style={{overflowX:'auto'}}>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    {createHeaders()}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {calculations.map((calcs) => (
                                    <TableRow className='tableRow'>
                                        <TableCell size="small" scope='row'>
                                            <Typography variant='subtitle2'>{calcs.vehicle}</Typography>
                                        </TableCell>
                                        {createBody(calcs)}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Grid>
                    : null}
            </Grid>
            <Button variant='contained' color="secondary" style={{ margin: '10px 0px 10px 24px' }} className='button'
                onClick={toggleTable}
                id='showFinancialsTableButton'>
                {showFinancialsTable ? <MinusIcon className='extendedIcon' /> : <AddIcon className='extendedIcon' />}
                <Typography variant='button'
                    className='typography'>{showFinancialsTable ? "Less Detail" : "More Detail"}</Typography>
            </Button>
        </Paper>
    )
};
